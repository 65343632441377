<div class="d-flex align-items-center flex-wrap gap-6" *ngIf="agGrid">
  <!-- View Management: Handles saving, switching, and managing different views for the grid -->
  <app-ag-grid-common-filter-view *ngIf="aGridCommonFieldsRef" [currentPageName]="currentPageName" [gridApi]="agGrid"
    [gridColumnApi]="agGrid" [agGridCommonFilterComponentRef]="filterComponentRef"
    [agGridCommonSortComponentRef]="agGridCommonSortRef" [aGridCommonFieldsRef]="aGridCommonFieldsRef"
    #agGridCommonFilterViewRef>
  </app-ag-grid-common-filter-view>
  <!-- Filter Button: Opens a menu to apply filters to the grid -->
  <button mat-button class="button--soft filter__button" id="btn_gridFilter"
    [ngClass]="{'filter__button--has-conditions': filterComponentRef?.validConditions?.length}"
    [matMenuTriggerFor]="menuFilter">
    <icon class="margin-end-s" name="filter_list"></icon>
    <ng-container *ngIf="filterComponentRef?.validConditions?.length; else elseTemplate">
      <span class="padding-end-s">{{ "gridFilter.filter" | translate }}</span>
      <span [matBadge]="filterComponentRef?.validConditions?.length || 0" matBadgeOverlap="false"
        matBadgeColor="primary"></span>
    </ng-container>
    <ng-template #elseTemplate>
      <span>{{ "gridFilter.filter" | translate }}</span>
    </ng-template>
  </button>
  <!-- Filter Menu: Contains the filter component which allows users to apply filters to the grid -->
  <mat-menu #menuFilter="matMenu" class="filter-menu filter-menu--with-overlay">
    <div (click)="$event.stopPropagation();" class="padding-base grid-common-filter-max-height">
      <app-ag-grid-common-filter *ngIf="agGrid" [gridApi]="agGrid" #filterComponentRef>
      </app-ag-grid-common-filter>
    </div>
  </mat-menu>
  <!-- Sort Button: Opens a menu to apply sorting to the grid -->
  <button mat-button class="button--soft filter__button" id="btn_gridSort"
    [ngClass]="{'filter__button--has-conditions': agGridCommonSortRef?.conditions?.length}"
    [matMenuTriggerFor]="menuSort">
    <icon class="margin-end-s" name="swap_vert"></icon>
    <span>
      {{ "gridFilter.sort" | translate }}
      <ng-container *ngIf="agGridCommonSortRef?.conditions?.length as conditionLength">
        : {{ conditionLength }} {{ 'gridFilter.' + (conditionLength > 1 ? 'fields' : 'field') | translate }}
      </ng-container>
    </span>
  </button>
  <!-- Sort Menu: Contains the sort component which allows users to apply sorting to the grid -->
  <mat-menu #menuSort="matMenu" class="filter-menu filter-menu--with-overlay">
    <div (click)="$event.stopPropagation();" class="padding-base">
      <app-ag-grid-common-sort *ngIf="agGrid" [gridColumnApi]="agGrid" #agGridCommonSortRef>
      </app-ag-grid-common-sort>
    </div>
  </mat-menu>
  <!-- Fields Button: Opens a menu to toggle column visibility and rearrange columns -->
  <button mat-button class="button--soft filter__button" id="btn_gridFields"
    [ngClass]="{'filter__button--has-conditions': aGridCommonFieldsRef?.hasChanges}" [matMenuTriggerFor]="menuFields">
    <icon class="margin-end-s" name="visibility"></icon>
    <span>{{ "gridFilter.Fields" | translate }}</span>
  </button>
  <!-- Fields Menu: Contains the fields component which allows users to toggle column visibility -->
  <mat-menu #menuFields="matMenu" class="filter-menu filter-menu--with-overlay">
    <div (click)="$event.stopPropagation();">
      <app-ag-grid-common-fields *ngIf="agGrid" [gridColumnApi]="agGrid" #aGridCommonFieldsRef>
      </app-ag-grid-common-fields>
    </div>
  </mat-menu>
  <!-- More Options Button -->
  <button mat-button class="button--soft filter__button filter__button--more-options" id="btn_gridMore"
    [matMenuTriggerFor]="menuMoreOptions">
    <icon name="more_vert"></icon>
  </button>
  <!-- More Options Menu -->
  <mat-menu #menuMoreOptions="matMenu" class="filter-menu filter-menu--more-options">
    <ng-content></ng-content>
  </mat-menu>
</div>
