<div cdkDropList (cdkDropListDropped)="onReorderFromDrop($event)">
    <!-- Loop through each sorting condition and display it in the UI -->
    <ng-container *ngFor="let condition of conditions; let i = index">
        <div class="d-flex align-items-center margin-bottom-base" cdkDrag>
            <!-- Drag handle for reordering conditions -->
            <icon name="drag_indicator" cdkDragHandle role="button" class="margin-end-m"></icon>
            <!-- Dropdown for selecting the column to sort by -->
            <div class="filter__dropdown margin-end-base">
                <mat-form-field appearance="outline">
                    <mat-select [placeholder]="('gridFilter.selectColumn' | translate)" (selectionChange)="applySort()" appearance="outline"
                        name="column1" [(ngModel)]="condition.column">
                        <ng-container *ngFor="let item of (condition?.columnDefs || [])">
                            <!-- Disable the option if the column is already selected in another condition -->
                            <mat-option *ngIf="item.field" [value]="item.field"
                                [disabled]="isColumnDisabled(item.field)"> {{item.headerName}} </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>
            <!-- Dropdown for selecting the sorting type (asc/desc) -->
            <div class="filter__dropdown margin-end-base">
                <mat-form-field appearance="outline">
                    <mat-select placeholder="Type" appearance="outline" name="type1" (selectionChange)="applySort()"
                        [(ngModel)]="condition.sortType">
                        <mat-option *ngFor="let item of defaultAgGridSortOperators" [value]="item.code"> {{item.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <!-- Button to remove the sorting condition -->
            <button mat-icon-button class="margin-start-l" (click)="removeCondition(condition)">
                <icon name="close"></icon>
            </button>
        </div>
    </ng-container>
</div>
<!-- Button to add another sorting condition -->
<button mat-button (click)="addCondition()">
    <icon name="add" class="margin-end-s"></icon>
    <span>{{"gridFilter.add_another_sort" | translate}}</span>
</button>