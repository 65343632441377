import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AuditLogService } from 'src/app/core/services/audit-log.service';
import { CustomFieldsService } from 'src/app/core/services/custom-fields.service';
import { AuditEvent, ChangeLog } from '../../models/audit-log';
import { AuditLogQuery } from '../../models/query';

interface ValueChange {
  property_name: string;
  original_value: string;
  new_value: string;
}

interface ExtraAttributes {
  validators: {
    required: boolean;
  };
  options?: string[];
}

interface BaseExtraAttributesChange {
  type: ExtraAttributesChangeType;
  label: string;
}

interface RequiredChange extends BaseExtraAttributesChange {
  type: 'required';
  before: boolean;
  after: boolean;
}

interface OptionsChange extends BaseExtraAttributesChange {
  type: 'options';
  before: string[];
  after: string[];
}

type ExtraAttributesChange = RequiredChange | OptionsChange;

type ExtraAttributesChangeType = 'required' | 'options';

@Component({
  selector: 'app-audit-log',
  templateUrl: './audit-log.component.html',
  styleUrls: ['./audit-log.component.scss']
})
export class AuditLogComponent implements OnInit {

  auditEvents: AuditEvent[] = [];
  query!: AuditLogQuery;
  dateLocale!: string;
  loading = false;
  endOfResults = false;
  requiredChange?: RequiredChange;
  optionsChange?: OptionsChange;
  customFields: any[] = [];

  constructor(private auditLogService: AuditLogService,
    private customFieldsService: CustomFieldsService,
    public translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public dialogData: any) { }

  ngOnInit(): void {
    this.query = {
      offset: 0,
      limit: 10,
      entity_id: this.dialogData?.entity_id
    };

    this.dateLocale = this.translateService.currentLang === 'en' ? 'en-US' : 'ar-SA';

    if (this.dialogData?.entity_id) {
      this.findAuditLogs();
    }
  }

  findAuditLogs() {
    this.loading = true;
    this.auditLogService.findAuditLogs(this.query).subscribe({
      next: (res: AuditEvent[]) => {
        if (res.find(e => e.entity_name === 'FixedAsset')) {
          this.loadCustomFields();
        }
        this.auditEvents = this.auditEvents.concat(res);
        this.endOfResults = res!.length < this.query.limit;
        this.loading = false;
      },
      error: (err: any) => {
        this.loading = false;
        this.endOfResults = true;
      }
    });
  }

  changeTitle(changeLog: ChangeLog): string {
    if (changeLog.change_type === 'UpdateHeader') {
      return this.translateService.instant('auditLog.UpdateHeader');
    }
    else {
      return `${this.translateService.instant('auditLog.' + changeLog.change_type)} ${this.translateService.instant(this.dialogData.translateKey + changeLog.detail_type)}  [${changeLog.detail_description}]`;
    }
  }

  getExtraAttributesChanges(valueChange: ValueChange): ExtraAttributesChange[] | null {
    if (valueChange.property_name !== 'ExtraAttributes') {
      return null;
    }

    try {
      const original: ExtraAttributes = JSON.parse(valueChange.original_value);
      const updated: ExtraAttributes = JSON.parse(valueChange.new_value);
      const changes: ExtraAttributesChange[] = [];

      // Check required changes
      if (original?.validators?.required !== updated?.validators?.required) {
        changes.push({
          type: 'required',
          label: this.translateService.instant('auditLog.extraAttributes.required'),
          before: original?.validators?.required || false,
          after: updated?.validators?.required || false
        });
      }

      // Check options changes
      if (original?.options?.join(',') !== updated?.options?.join(',')) {
        changes.push({
          type: 'options',
          label: this.translateService.instant('auditLog.extraAttributes.options'),
          before: original?.options || [],
          after: updated?.options || []
        });
      }

      return changes.length ? changes : null;
    } catch (error) {
      console.error('Error parsing ExtraAttributes:', error);
      return null;
    }
  }

  isOptionsChange(change: ExtraAttributesChange): change is OptionsChange {
    return change.type === 'options';
  }

  isRequiredChange(change: ExtraAttributesChange): change is RequiredChange {
    return change.type === 'required';
  }

  getCustomFieldName(fieldId: string): string {
    const field = this.customFields.find(f => f.id === fieldId);
    return field ? field.name : fieldId;
  }

  getChangedCustomFieldIds(valueChange: ValueChange): string[] {
    try {
      const original = JSON.parse(valueChange.original_value || '{}').customFields || {};
      const updated = JSON.parse(valueChange.new_value || '{}').customFields || {};
      
      // Get all unique field IDs from both objects
      const fieldIds = new Set([
        ...Object.keys(original),
        ...Object.keys(updated)
      ]);
      
      // Return only fields that have changed
      return Array.from(fieldIds).filter(fieldId => 
        original[fieldId] !== updated[fieldId]
      );
    } catch (error) {
      console.error('Error parsing custom fields:', error);
      return [];
    }
  }

  getCustomFieldValue(jsonString: string, fieldId: string): string {
    try {
      const values = JSON.parse(jsonString || '{}').customFields || {};
      return values[fieldId]?.toString() || '';
    } catch (error) {
      console.error('Error parsing custom field value:', error);
      return '';
    }
  }

  private loadCustomFields() {
    this.customFieldsService.getAllCustomFields().subscribe({
      next: (fields) => {
        this.customFields = fields;
      }
    });
  }

  hasCustomFieldsChanges(valueChange: ValueChange): boolean {
    try {
      const original = JSON.parse(valueChange.original_value || '{}');
      const updated = JSON.parse(valueChange.new_value || '{}');
      
      // Check if this is a customFields change
      return (
        original.hasOwnProperty('customFields') || 
        updated.hasOwnProperty('customFields')
      );
    } catch (error) {
      console.error('Error parsing ExtraAttributes:', error);
      return false;
    }
  }

}
