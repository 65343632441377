import moment from "moment";
import { CashRegister } from "./cash-register";
import { SalesOrderItem } from "./sales-order-item";
import { SalesOrder } from "./sales-order";
import { flatMap, maxBy, orderBy, remove, sumBy } from "lodash";

export class Promotion {

  id?: string;
  name!: string;
  description?: string;
  startDate!: Date;
  endDate!: Date;
  createdDate?: Date;
  promotionRule: PromotionRule = new PromotionRule();

  get promotionType(): PromotionType {
    const promotionTypeMap: Record<keyof PromotionRule, PromotionType> = {
      productDiscount: PromotionType.ProductDiscount,
      orderDiscount: PromotionType.OrderDiscount,
      buyXGetY: PromotionType.BuyXGetY
    };

    const promotionRule = this.promotionRule;
    const promotionType = Object.keys(promotionTypeMap).find(
      (key): key is keyof PromotionRule => promotionRule[key as keyof PromotionRule] != null
    );

    return promotionTypeMap[promotionType!];
  }

  public static clone(promotion?: Promotion): Promotion | undefined {
    if (!promotion) return undefined;
    const newPromotion = Object.assign(new Promotion(), promotion);
    return newPromotion;
  }

  public static isLineItemPromotionValid(saleOrderItem: SalesOrderItem, promotion: Promotion, salesOrder: SalesOrder) {
    if (!promotion)
      return false;

    // if order discount then it is not valid
    if (promotion.promotionRule.orderDiscount) return false;

    if (promotion.promotionRule.productDiscount)
      return ProductDiscountPromotionRule.canApply(promotion.promotionRule.productDiscount, saleOrderItem, salesOrder.cashRegister);

    if (promotion.promotionRule.buyXGetY)
      return BuyXGetYPromotionRule.canApply(promotion.promotionRule.buyXGetY, saleOrderItem, salesOrder);

    return false;
  }

  public static isOrderPromotionValid(promotion?: Promotion, cashRegister?: CashRegister) {
    if (!promotion)
      return false;

    // Assure target type is Order
    if (!promotion.promotionRule.orderDiscount)
      return false;

    return OrderDiscountPromotionRule.canApply(promotion.promotionRule.orderDiscount, cashRegister);
  }

  static applyToOrder(salesOrder: SalesOrder, promotion?: Promotion) {
    if (!Promotion.isOrderPromotionValid(promotion, salesOrder.cashRegister)) {
      salesOrder.discount_rate = 0;
      salesOrder.discountValue = 0;
      return undefined;
    }

    const orderDiscountRule = promotion!.promotionRule.orderDiscount!;
    if (orderDiscountRule.valueType === DiscountValueType.FixedAmount) {
      salesOrder.discount_rate = 0;
      salesOrder.discountValue = orderDiscountRule.value;
    } else if (orderDiscountRule.valueType === DiscountValueType.Percentage) {
      salesOrder.discountValue = 0;
      salesOrder.discount_rate = orderDiscountRule.value;
    }

    return promotion;
  }

  static applyBestPromotionToOrder(salesOrder: SalesOrder, promotions: Promotion[]): Promotion | undefined {
    const matchedPromos = promotions
      .filter(x =>
        Promotion.isOrderPromotionValid(x, salesOrder.cashRegister) &&
        x.promotionRule.orderDiscount!.entitledCustomerTargetSelection === PromotionTargetSelection.All
      );

    const bestPromotion = maxBy(matchedPromos, x => x.createdDate);
    if (!bestPromotion) return undefined;

    return Promotion.applyToOrder(salesOrder, bestPromotion);
  }

  static applyPromotionsToOrderItems(salesOrder: SalesOrder, promotions: Promotion[]): void {
    // Reset all promotions and merge benefit items back
    Promotion.resetPromotions(salesOrder);

    // Separate promotions by type
    const buyXGetYPromotions = promotions.filter(promo => promo.promotionRule.buyXGetY);
    const productPromotions = promotions.filter(promo => promo.promotionRule.productDiscount);

    // Filter applicable BuyXGetY promotions
    const applicableBuyXGetYPromotions = buyXGetYPromotions.filter(promotion => {
      // Check if promotion can be applied based on current order
      const rule = promotion.promotionRule.buyXGetY!;
      return salesOrder.salesOrder_details.some(item =>
        BuyXGetYPromotionRule.canApply(rule, item, salesOrder)
      );
    });

    // Apply BuyXGetY Promotions to remaining items
    BuyXGetYPromotionRule.applyPromotions(salesOrder, applicableBuyXGetYPromotions);

    // Apply Product Discounts to items not involved in BuyXGetY promotions
    for (const item of salesOrder.salesOrder_details) {
      if (!item.promotion && !item.isBuyXGetYPrerequisite && !item.isBenefit) {
        Promotion.applyProductDiscount(item, productPromotions, salesOrder);
      }
    }
  }

  private static resetPromotions(salesOrder: SalesOrder): void {
    const items = salesOrder.salesOrder_details;

    // Combine items with same stock_item_id and reset promotions
    for (const item of items.filter(x => x.isBenefit || x.isBuyXGetYPrerequisite || x.promotion)) {
        item.isBenefit = false;
        item.isBuyXGetYPrerequisite = false;
        item.promotion = undefined;
        item.item_discount = 0;
        item.item_discount_type = DiscountValueType.FixedAmount;
    }

    salesOrder.resetOrderItemsSerials();
  }

  private static applyProductDiscount(item: SalesOrderItem, productPromotions: Promotion[], salesOrder: SalesOrder): void {
    const applicablePromotions = productPromotions
      .filter(x => !!x.promotionRule.productDiscount)
      .filter(x => Promotion.isLineItemPromotionValid(item, x, salesOrder));
    const bestPromotion = maxBy(applicablePromotions, x => x.createdDate);

    if (bestPromotion) {
      const rule = bestPromotion.promotionRule.productDiscount!;
      item.item_discount_type = rule.valueType;
      item.item_discount = rule.value;
      item.promotion = bestPromotion.name;
    }
  }
}

export class PromotionRule {
  productDiscount?: ProductDiscountPromotionRule;
  orderDiscount?: OrderDiscountPromotionRule;
  buyXGetY?: BuyXGetYPromotionRule;
}

export class ProductDiscountPromotionRule {
  entitledCashRegisterTargetSelection: PromotionTargetSelection = PromotionTargetSelection.All;
  entitledCashRegisterIds?: string[];
  entitledProductTargetSelection: PromotionTargetSelection = PromotionTargetSelection.All;
  entitledProductSelectionMode?: PromotionSelectionMode;
  entitledProductIds?: string[];
  valueType: DiscountValueType = DiscountValueType.Percentage;
  value!: number;
  period: PromotionPeriod = new PromotionPeriod();

  public static canApply(rule: ProductDiscountPromotionRule, saleOrderItem: SalesOrderItem, cashRegister?: CashRegister) {
    // Check if stock item
    if (!saleOrderItem.stock_item_id) return false;

    // Check day selection
    if (!PromotionPeriod.isCurrent(rule.period)) return false;

    // Check cash register
    if (
      rule.entitledCashRegisterTargetSelection === PromotionTargetSelection.Entitled
      && (!cashRegister || !rule.entitledCashRegisterIds?.includes(cashRegister.id))
    ) return false;

    // Check item
    if (rule.entitledProductTargetSelection === PromotionTargetSelection.Entitled) {
      const productId = saleOrderItem.stock_item_id!.toString();
      if (rule.entitledProductSelectionMode === PromotionSelectionMode.Include) {
        if (!rule.entitledProductIds?.includes(productId)) return false;
      } else if (rule.entitledProductSelectionMode === PromotionSelectionMode.Exclude) {
        if (rule.entitledProductIds?.includes(productId)) return false;
      }
    }

    return true;
  }
}

export class OrderDiscountPromotionRule {
  entitledCashRegisterTargetSelection: PromotionTargetSelection = PromotionTargetSelection.All;
  entitledCashRegisterIds?: string[];
  entitledCustomerTargetSelection: PromotionTargetSelection = PromotionTargetSelection.All;
  promoCode?: string;
  valueType: DiscountValueType = DiscountValueType.Percentage;
  value!: number;
  period: PromotionPeriod = new PromotionPeriod();

  public static canApply(rule: OrderDiscountPromotionRule, cashRegister?: CashRegister) {
    return PromotionPeriod.isCurrent(rule.period) &&
      (rule.entitledCashRegisterTargetSelection !== PromotionTargetSelection.Entitled ||
        cashRegister && rule.entitledCashRegisterIds?.includes(cashRegister.id));
  }
}

export class BuyXGetYPromotionRule {
  entitledCashRegisterTargetSelection: PromotionTargetSelection = PromotionTargetSelection.All;
  entitledCashRegisterIds?: string[];
  period: PromotionPeriod = new PromotionPeriod();
  prerequisiteQuantity!: number;
  prerequisiteProductTargetSelection: PromotionTargetSelection = PromotionTargetSelection.All;
  prerequisiteProductSelectionMode?: PromotionSelectionMode;
  prerequisiteProductIds?: string[];
  entitledQuantity!: number;
  entitledProductTargetSelection: PromotionTargetSelection = PromotionTargetSelection.All;
  entitledProductSelectionMode?: PromotionSelectionMode;
  entitledProductIds?: string[];
  benefitType: BuyXGetYBenefitType = BuyXGetYBenefitType.FreeProduct;
  discountValue!: number;

  public static canApply(rule: BuyXGetYPromotionRule, saleOrderItem: SalesOrderItem, salesOrder: SalesOrder) {
    // Check if stock item
    if (!saleOrderItem.stock_item_id) return false;

    // Check day selection
    if (!PromotionPeriod.isCurrent(rule.period)) return false;

    // Check cash register
    if (
      rule.entitledCashRegisterTargetSelection === PromotionTargetSelection.Entitled
      && (!salesOrder.cashRegister || !rule.entitledCashRegisterIds?.includes(salesOrder.cashRegister.id))
    ) return false;

    // Ensure the item is eligible based on the promotion's product selection
    const isEntitled = BuyXGetYPromotionRule.isItemEntitled(rule, saleOrderItem);
    const isPrerequisite = BuyXGetYPromotionRule.isItemPrerequisite(rule, saleOrderItem);

    // The item must be either a prerequisite or an entitled item
    if (!isEntitled && !isPrerequisite) return false;

    // Check if total quantities satisfy both prerequisite and entitled quantities
    const totalQuantitiesSatisfy = BuyXGetYPromotionRule.totalQuantitiesSatisfyRule(rule, salesOrder);
    if (!totalQuantitiesSatisfy) return false;

    return true;
  }

  private static isItemEntitled(rule: BuyXGetYPromotionRule, item: SalesOrderItem): boolean {
    if (rule.entitledProductTargetSelection === PromotionTargetSelection.All) return true;
    const productId = item.stock_item_id!.toString();
    if (rule.entitledProductSelectionMode === PromotionSelectionMode.Include) {
      return rule.entitledProductIds?.includes(productId) ?? false;
    } else if (rule.entitledProductSelectionMode === PromotionSelectionMode.Exclude) {
      return !(rule.entitledProductIds?.includes(productId) ?? false);
    }
    return false;
  }

  private static isItemPrerequisite(rule: BuyXGetYPromotionRule, item: SalesOrderItem): boolean {
    if (rule.prerequisiteProductTargetSelection === PromotionTargetSelection.All) return true;
    const productId = item.stock_item_id!.toString();
    if (rule.prerequisiteProductSelectionMode === PromotionSelectionMode.Include) {
      return rule.prerequisiteProductIds?.includes(productId) ?? false;
    } else if (rule.prerequisiteProductSelectionMode === PromotionSelectionMode.Exclude) {
      return !(rule.prerequisiteProductIds?.includes(productId) ?? false);
    }
    return false;
  }

  private static totalQuantitiesSatisfyRule(rule: BuyXGetYPromotionRule, salesOrder: SalesOrder): boolean {
    // Determine items that are eligible as prerequisites and entitled
    const items = salesOrder.salesOrder_details.filter(item => !item.isBenefit && !item.isBuyXGetYPrerequisite && !item.promotion);

    const isPrerequisite = (item: SalesOrderItem) => BuyXGetYPromotionRule.isItemPrerequisite(rule, item);
    const isEntitled = (item: SalesOrderItem) => BuyXGetYPromotionRule.isItemEntitled(rule, item);

    const prerequisiteItems = items.filter(isPrerequisite);
    const entitledItems = items.filter(isEntitled);

    const totalPrerequisiteQuantity = sumBy(prerequisiteItems, 'quantity');
    const totalEntitledQuantity = sumBy(entitledItems, 'quantity');

    // Handle overlapping products
    const overlappingItems = prerequisiteItems.filter(item => entitledItems.includes(item));
    const totalOverlappingQuantity = sumBy(overlappingItems, 'quantity');

    let effectivePrerequisiteQuantity = totalPrerequisiteQuantity;
    let effectiveEntitledQuantity = totalEntitledQuantity;

    // Calculate how many sets can be made considering overlaps
    let sets = 0;

    while (true) {
      let requiredPrerequisite = rule.prerequisiteQuantity;
      let requiredEntitled = rule.entitledQuantity;

      const overlapAvailable = totalOverlappingQuantity - (sets * (rule.prerequisiteQuantity + rule.entitledQuantity));

      const overlapForPrerequisite = Math.min(requiredPrerequisite, overlapAvailable);
      requiredPrerequisite -= overlapForPrerequisite;

      const overlapForEntitled = Math.min(requiredEntitled, overlapAvailable - overlapForPrerequisite);
      requiredEntitled -= overlapForEntitled;

      if (
        requiredPrerequisite <= (effectivePrerequisiteQuantity - (sets * rule.prerequisiteQuantity)) &&
        requiredEntitled <= (effectiveEntitledQuantity - (sets * rule.entitledQuantity)) &&
        (overlapForPrerequisite + overlapForEntitled) <= overlapAvailable
      ) {
        sets += 1;
      } else {
        break;
      }
    }

    return sets > 0;
  }

  public static applyPromotions(salesOrder: SalesOrder, buyXGetYPromotions: Promotion[]) {
    if (buyXGetYPromotions.length === 0) return;

    buyXGetYPromotions = orderBy(buyXGetYPromotions, ['createdDate'], ['desc']);

    // Apply promotions to sales order
    for (const promotion of buyXGetYPromotions) {
      const rule = promotion.promotionRule.buyXGetY!;
      const discountValue = rule.benefitType === BuyXGetYBenefitType.FreeProduct ? 100 : rule.discountValue;

      const isEntitled = (x: SalesOrderItem) => BuyXGetYPromotionRule.isItemEntitled(rule, x);
      const isPrerequisite = (x: SalesOrderItem) => BuyXGetYPromotionRule.isItemPrerequisite(rule, x);

      // Filter items
      let items = (salesOrder.salesOrder_details as (SalesOrderItem & { quantityAvailable: number })[])
        .filter(x => !x.isBenefit && !x.isBuyXGetYPrerequisite && !x.promotion);

      // Separate overlapping items
      let overlappingItems = items.filter(x => isPrerequisite(x) && isEntitled(x));
      let prerequisiteOnlyItems = items.filter(x => isPrerequisite(x) && !isEntitled(x));
      let entitledOnlyItems = items.filter(x => isEntitled(x) && !isPrerequisite(x));

      // Initialize quantityAvailable for all items
      for (const item of overlappingItems.concat(prerequisiteOnlyItems, entitledOnlyItems)) {
        item.quantityAvailable = item.quantity;
      }

      let sets = 0;
      let setDetails = [];

      while (true) {
        let requiredPrerequisite = rule.prerequisiteQuantity;
        let requiredEntitled = rule.entitledQuantity;

        // Prerequisite items: overlappingItems + prerequisiteOnlyItems
        let availablePrerequisiteItems = overlappingItems.concat(prerequisiteOnlyItems)
          .filter(item => item.quantityAvailable > 0);

        // Sort in descending order of price
        availablePrerequisiteItems = orderBy(availablePrerequisiteItems, ['price', 'serial'], ['desc', 'desc']);

        let usedPrerequisiteItems: { item: SalesOrderItem; quantityUsed: number }[] = [];

        for (const item of availablePrerequisiteItems) {
          if (requiredPrerequisite <= 0) break;

          const quantityUsed = Math.min(item.quantityAvailable, requiredPrerequisite);
          if (quantityUsed > 0) {
            usedPrerequisiteItems.push({ item, quantityUsed });
            item.quantityAvailable -= quantityUsed;
            requiredPrerequisite -= quantityUsed;
          }
        }

        // Benefit items: overlappingItems + entitledOnlyItems
        let availableBenefitItems = overlappingItems.concat(entitledOnlyItems)
          .filter(item => item.quantityAvailable > 0);

        // Sort in ascending order of price
        availableBenefitItems = orderBy(availableBenefitItems, ['price', 'serial'], ['asc', 'desc']);

        let usedBenefitItems: { item: SalesOrderItem; quantityUsed: number }[] = [];

        for (const item of availableBenefitItems) {
          if (requiredEntitled <= 0) break;

          const quantityUsed = Math.min(item.quantityAvailable, requiredEntitled);
          if (quantityUsed > 0) {
            usedBenefitItems.push({ item, quantityUsed });
            item.quantityAvailable -= quantityUsed;
            requiredEntitled -= quantityUsed;
          }
        }

        if (requiredPrerequisite === 0 && requiredEntitled === 0) {
          sets += 1;
          setDetails.push({
            usedPrerequisiteItems,
            usedBenefitItems
          });
        } else {
          break; // Cannot form more sets
        }
      }

      if (sets === 0) continue; // Cannot apply promotion

      const usedPrerequisiteItems = flatMap(setDetails, x => x.usedPrerequisiteItems);
      const usedBenefitItems = flatMap(setDetails, x => x.usedBenefitItems);

      // Now, apply the promotion sets
      // Mark prerequisite items
      BuyXGetYPromotionRule.markItemsAsPrerequisite(usedPrerequisiteItems, salesOrder);

      // Mark benefit items
      BuyXGetYPromotionRule.markItemsAsBenefit(usedBenefitItems, salesOrder, promotion, discountValue);

      // Reset serials after adding new items
      salesOrder.resetOrderItemsSerials();
    }
  }

  private static markItemsAsPrerequisite(
    usedItems: { item: SalesOrderItem; quantityUsed: number }[],
    salesOrder: SalesOrder
  ) {
    for (const { item, quantityUsed } of usedItems) {
      // Check if a prerequisite item with the same stock_item_id and promotion already exists
      let existingPrerequisiteItem = salesOrder.salesOrder_details.find(
        x =>
          x.isBuyXGetYPrerequisite &&
          x.stock_item_id === item.stock_item_id
      );

      if (existingPrerequisiteItem) {
        // Reduce quantity from the original item
        if (item.quantity === quantityUsed) {
          salesOrder.salesOrder_details.splice(
            salesOrder.salesOrder_details.indexOf(item),
            1
          );
        } else {
          item.quantity -= quantityUsed;
        }

        // Increase quantity of the existing prerequisite item
        existingPrerequisiteItem.quantity += quantityUsed;
      } else {
        if (item.quantity === quantityUsed) {
          // Mark the item as prerequisite
          item.isBuyXGetYPrerequisite = true;
        } else {
          // Split the item
          const newItem = SalesOrderItem.clone(item);
          newItem.quantity = quantityUsed;
          newItem.isBuyXGetYPrerequisite = true;

          item.quantity -= quantityUsed;
          salesOrder.salesOrder_details.splice(
            salesOrder.salesOrder_details.indexOf(item) + 1,
            0,
            newItem
          );
        }
      }
    }
  }

  private static markItemsAsBenefit(
    usedItems: { item: SalesOrderItem; quantityUsed: number }[],
    salesOrder: SalesOrder,
    promotion: Promotion,
    discountValue: number
  ) {
    for (const { item, quantityUsed } of usedItems) {
      // Check if a benefit item with the same stock_item_id and promotion already exists
      let existingBenefitItem = salesOrder.salesOrder_details.find(
        x =>
          x.isBenefit &&
          x.stock_item_id === item.stock_item_id &&
          x.promotion === promotion.name
      );

      if (existingBenefitItem) {
        // Reduce quantity from the original item
        if (item.quantity === quantityUsed) {
          remove(salesOrder.salesOrder_details, item);
        } else {
          item.quantity -= quantityUsed;
        }

        // Increase quantity of the existing benefit item
        existingBenefitItem.quantity += quantityUsed;
      } else {
        if (item.quantity === quantityUsed) {
          // Mark the item as benefit
          item.isBenefit = true;
          item.promotion = promotion.name;
          item.item_discount_type = DiscountValueType.Percentage;
          item.item_discount = discountValue;
        } else {
          // Split the item
          const newItem = SalesOrderItem.clone(item);
          newItem.quantity = quantityUsed;
          newItem.isBenefit = true;
          newItem.promotion = promotion.name;
          newItem.item_discount_type = DiscountValueType.Percentage;
          newItem.item_discount = discountValue;

          item.quantity -= quantityUsed;
          salesOrder.salesOrder_details.splice(
            salesOrder.salesOrder_details.indexOf(item) + 1,
            0,
            newItem
          );
        }
      }
    }
  }
}

export class PromotionPeriod {
  from!: Date;
  to!: Date;
  daySelection: PromotionDaySelection = PromotionDaySelection.All;
  days?: string[];

  static isCurrent(period: PromotionPeriod) {
    return period.daySelection === PromotionDaySelection.All
      || period.days?.includes(DayOfWeek[moment().day()].toString());
  }
}

export enum PromotionTargetSelection {
  All = 0,
  Entitled = 1
}

export enum PromotionSelectionMode {
  Include = 0,
  Exclude = 1
}

export enum DiscountValueType {
  FixedAmount = 0,
  Percentage = 1
}

export enum PromotionDaySelection {
  All = 0,
  Prerequisite = 1
}

export enum BuyXGetYBenefitType {
  FreeProduct = 0,
  Discount = 1
}

export enum PromotionStatus {
  Past = 0,
  Current = 1,
  Upcoming = 2
}

export enum DayOfWeek {
  SUN = 0,
  MON = 1,
  TUE = 2,
  WED = 3,
  THU = 4,
  FRI = 5,
  SAT = 6
}

export enum PromotionType {
  ProductDiscount = 0,
  OrderDiscount = 1,
  BuyXGetY = 2
}
