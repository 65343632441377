import { Injectable } from '@angular/core';
import { DocumentReference, deleteDoc, setDoc } from '@angular/fire/firestore';
import { AuthService } from '@auth';
import _ from 'lodash';
import { FirestoreService } from '@core';
import { AgGridCommonFilterViewItem } from '@shared/models/ag-grid-common-filter.model';
import { CollectionReference, query, where } from 'firebase/firestore';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AgGridCommonFilterService {
  saveChanges$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  currentTenant: string;

  private get agGridFilterViewDocumentsCollection(): CollectionReference<AgGridCommonFilterViewItem> {
    return this.firestoreService.col(`accountSettings/${this.authService.currentUserId}/tablefilterViews`);
  }
  getAgGridCommonFilterViewItem(docId:any): DocumentReference<AgGridCommonFilterViewItem> {
    return this.firestoreService.doc(`accountSettings/${this.authService.currentUserId}/tablefilterViews/${docId}`);
  }
  private _AllFilters: BehaviorSubject<AgGridCommonFilterViewItem[] | null> = new BehaviorSubject<AgGridCommonFilterViewItem[] | null>(null);
  public AllFilters$ = this._AllFilters.asObservable();

  get AllFilters(): AgGridCommonFilterViewItem[] | null {
    return this._AllFilters.value;
  }
  set AllFilters(value: AgGridCommonFilterViewItem[]) {
    localStorage.setItem('AGfilter', JSON.stringify(value));
    this._AllFilters.next(value)
  }

  constructor(
    private authService: AuthService,
    private firestoreService: FirestoreService) {
      this.currentTenant = this.authService.currentTenant;
      this.loadData()
      this.authService.tenantSwitched$.subscribe((tenantId) => {
        if(this.currentTenant !== tenantId){
          this.currentTenant = tenantId
          this.loadAgGridFilterView();  
        }       
      })

  }

  loadData(){
    let cashedData = localStorage.getItem('AGfilter');
    if(cashedData){
      this.AllFilters = JSON.parse(cashedData);
    }else{
      this.loadAgGridFilterView();
    }
  }
  clearData(){
    localStorage.removeItem('AGfilter');
  }

  loadAgGridFilterView() {
    const queryFn = query(this.firestoreService.col<any>(`accountSettings/${this.authService.currentUserId}/tablefilterViews`), where('tenantId', '==', this.currentTenant));
    this.firestoreService.getCollectionWithDocIdAsync<any>(queryFn).then((filters) => {
      this.AllFilters = filters;
    })
  }


  addAgGridFilterView(data: AgGridCommonFilterViewItem) {
    return this.firestoreService.addDoc(this.agGridFilterViewDocumentsCollection, { ...data, tenantId: this.authService.currentTenant });
  }

  saveAgGridFilterView(data: AgGridCommonFilterViewItem) {
    return setDoc(this.getAgGridCommonFilterViewItem(data.docId), { ...data, tenantId: this.authService.currentTenant });
  }

  updateAgGridFilterView(data: any) {
    return setDoc(this.getAgGridCommonFilterViewItem(data.docId), { ...data, tenantId: this.authService.currentTenant }, { merge: true });
  }
  deleteAgGridFilterView(docId: any) {
    return deleteDoc(this.getAgGridCommonFilterViewItem(docId));
  }
}
