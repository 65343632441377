<div class="dialog__height">
  <div mat-dialog-title>
    <div class="dialog__header no_border-bottom">
      <h4 class="typo-title">
        {{ "hardDeleteConfirmationDialog.title" | translate: {entityName: dialogData.entityName} }}
      </h4>
      <button id="btn_hardDeleteClose" mat-icon-button mat-dialog-close>
        <icon name="close"></icon>
      </button>
    </div>
  </div>
  <mat-dialog-content>
    <div class="warnning-msg__container font-size-base mb-3">
      {{ dialogData.message }}
    </div>
    <div class="row">
      <div class="col-md-6">
        <mat-form-field class="field-space-none">
          <input matInput [(ngModel)]="confirmText"
            [placeholder]="('hardDeleteConfirmationDialog.confirmText' | translate)">
        </mat-form-field>
        <p class="text-gray mb-0">
          {{ "hardDeleteConfirmationDialog.typeDeleteToConfirm" | translate }}
        </p>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="dialog__footer justify-content-end no_border-top">
    <button id="btn_hardDeleteDiscard" mat-button color="primary" mat-dialog-close>
      {{'buttons.discard' | translate }}
    </button>
    <button id="btn_hardDeleteConfirm" mat-flat-button color="warn"
      [disabled]="confirmText.toLowerCase() !== ('hardDeleteConfirmationDialog.confirmText' | translate).toLowerCase()"
      (click)="onConfirm()">
      {{'buttons.delete' | translate }}
    </button>
  </mat-dialog-actions>
</div>