import { ColDef, ColGroupDef } from 'ag-grid-community';
import { Pipe, PipeTransform } from '@angular/core';
import { FilterConditions } from '@shared/components/ag-grid-common-filter/ag-grid-common-filter.model';

@Pipe({
  name: 'agFilterColumns'
})
export class AgFilterColumnsPipe implements PipeTransform {

  transform(columnDefs: (ColDef | ColGroupDef)[] | undefined, conditions: FilterConditions[] = [],count:number = 2): (ColDef | ColGroupDef)[] {
   let  filteredList: (ColDef | ColGroupDef)[] = [];
   if(columnDefs?.length) {
    filteredList = columnDefs.map((columnDef: any ) => {
      columnDef.isDisabled =  !(conditions.filter((cond:FilterConditions) => cond.column === columnDef.field)?.length < count);
      return columnDef
    })
   }
    return filteredList;
  }

}
