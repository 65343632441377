import { ColDef, ColGroupDef } from 'ag-grid-community';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getAgFilterColumn'
})
export class GetAgFilterColumnPipe implements PipeTransform {

  transform(columnDefs: (ColDef | ColGroupDef)[] | undefined, columnField: string = '') : ColDef | undefined {
    let  columnRef: ColDef | undefined;
    if(columnDefs?.length) {
      columnRef = columnDefs.find((columnDef: ColDef ) => columnDef.field === columnField)
    }
     return columnRef;
   }

}
