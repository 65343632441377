import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { Observable, firstValueFrom, from } from 'rxjs';
import { EdaraSetting } from '../../shared/models/edara-setting';
import { AuthService } from '@auth';
import { shareReplay, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EdaraSettingsService {

  private noOfDecimalPlacesMoney$?: Observable<number>;
  private noOfDecimalPlacesQty$?: Observable<number>;
  // private allowDiscountPerStockItem$: Observable<boolean> = null;
  // private pricesIncludeVAT$: Observable<boolean> = null;
  // private SOIsTaxableByDefault$: Observable<boolean> = null;
  // private allowSalesDiscounts$: Observable<boolean> = null;
  // private createVATJournalEntriesAllowed$: Observable<boolean> = null;
  // private applyTax$: Observable<string> = null;
  // private useUnitOfMeasure$: Observable<boolean> = null;
  // private defaultPaymentForUsers$: Observable<boolean> = null;
  // private defaultPaymentCashUsersIds$: Observable<string> = null;
  // private allowUnpaidSalesOrders$: Observable<boolean> = null;
  private defaultValuationCost$?: Observable<string>;
  // private allowSalesOrderWithZeroTotal$: Observable<boolean> = null;
  // private allowZeroPriceInSalesOrderDetails$: Observable<boolean> = null;
  // private allowChangeSalesPrice$: Observable<boolean> = null;
  private nameUnique$?: Observable<boolean>;
  private codeUnique$?: Observable<boolean>;
  private codeRequired$?: Observable<boolean>;
  private emailUnique$?: Observable<boolean>;
  private emailRequired$?: Observable<boolean>;
  private mobileUnique$?: Observable<boolean>;
  private mobileRequired$?: Observable<boolean>;
  private phoneUnique$?: Observable<boolean>;
  private phoneRequired$?: Observable<boolean>;
  private taxRegistrationId$?: Observable<string>;
  private organizationName$?: Observable<string>;

  constructor(
    private commonService: CommonService,
    private authService: AuthService) {
    this.authService.tenantSwitched$.subscribe({
      next: () => this.clearCache()
    });

    if (this.authService.isAuthenticated()) {
      this.preLoadSettings();
    }
  }

  async noOfDecimalPlacesMoney(): Promise<number> {
    if (!this.noOfDecimalPlacesMoney$) {
      this.noOfDecimalPlacesMoney$ = from(this.getSettingValue('System', 'NumberOfDecimalPlacesInMoneyField')).pipe(
        shareReplay(1)
      );
    }

    return firstValueFrom(this.noOfDecimalPlacesMoney$);
    // return from(this.getSettingValue('System', 'NumberOfDecimalPlacesInMoneyField')).pipe(
    //   map(res => res)
    // ).toPromise();
  }

  async noOfDecimalPlacesQty(): Promise<number> {
    if (!this.noOfDecimalPlacesQty$) {
      this.noOfDecimalPlacesQty$ = from(this.getSettingValue('System', 'NumberOfDecimalPlacesInQuantityField')).pipe(
        shareReplay(1)
      );
    }
    return firstValueFrom(this.noOfDecimalPlacesQty$);
    // return from(this.getSettingValue('System', 'NumberOfDecimalPlacesInQuantityField')).pipe(
    //   map(res => res)
    // ).toPromise();
  }

  async allowChangeSalesPrice(): Promise<boolean> {
    // if (!this.allowChangeSalesPrice$) {
    //   this.allowChangeSalesPrice$ = from(this.getSettingValue('Sales', 'PreventChangeSalesPrice')).pipe(
    //     map(res => !this.isTrue(res)),
    //     shareReplay(1)
    //   );
    // }
    // return this.allowChangeSalesPrice$.toPromise();
    return firstValueFrom(
      from(this.getSettingValue('Sales', 'PreventChangeSalesPrice')).pipe(
        map(res => !this.isTrue(res))
      ));
  }

  async allowDiscountPerStockItem(): Promise<boolean> {
    // if (!this.allowDiscountPerStockItem$) {
    //   this.allowDiscountPerStockItem$ = from(this.getSettingValue('Sales', 'AllowDiscountPerStockItem')).pipe(
    //     map(res => this.isTrue(res)),
    //     shareReplay(1)
    //   );
    // }
    // return this.allowDiscountPerStockItem$.toPromise();
    return firstValueFrom(
      from(this.getSettingValue('Sales', 'AllowDiscountPerStockItem')).pipe(
        map(res => this.isTrue(res))
      ));
  }

  async pricesIncludeVAT(): Promise<boolean> {
    // if (!this.pricesIncludeVAT$) {
    //   this.pricesIncludeVAT$ = from(this.getSettingValue('Sales', 'PricesIncludeVAT')).pipe(
    //     map(res => this.isTrue(res)),
    //     shareReplay(1)
    //   );
    // }
    // return this.pricesIncludeVAT$.toPromise();
    return firstValueFrom(
      from(this.getSettingValue('Sales', 'PricesIncludeVAT')).pipe(
        map(res => this.isTrue(res))
      ));
  }

  async SOIsTaxableByDefault(): Promise<boolean> {
    // if (!this.SOIsTaxableByDefault$) {
    //   this.SOIsTaxableByDefault$ = from(this.getSettingValue('Sales', 'SOisTaxableByDefault')).pipe(
    //     map(res => this.isTrue(res)),
    //     shareReplay(1)
    //   );
    // }
    // return this.SOIsTaxableByDefault$.toPromise();
    return firstValueFrom(
      from(this.getSettingValue('Sales', 'SOisTaxableByDefault')).pipe(
        map(res => this.isTrue(res))
      ));
  }

  async allowSalesDiscounts(): Promise<boolean> {
    // if (!this.allowSalesDiscounts$) {
    //   this.allowSalesDiscounts$ = from(this.getSettingValue('Sales', 'AllowSalesDiscounts')).pipe(
    //     map(res => this.isTrue(res)),
    //     shareReplay(1)
    //   );
    // }
    // return this.allowSalesDiscounts$.toPromise();
    return firstValueFrom(
      from(this.getSettingValue('Sales', 'AllowSalesDiscounts')).pipe(
        map(res => this.isTrue(res))
      ));
  }

  async createVATJournalEntriesAllowed(): Promise<boolean> {
    // if (!this.createVATJournalEntriesAllowed$) {
    //   this.createVATJournalEntriesAllowed$ = from(this.getSettingValue('Sales', 'IsCreateVATJournalEntries')).pipe(
    //     map(res => this.isTrue(res)),
    //     shareReplay(1)
    //   );
    // }
    // return this.createVATJournalEntriesAllowed$.toPromise();
    return firstValueFrom(
      from(this.getSettingValue('Sales', 'IsCreateVATJournalEntries')).pipe(
        map(res => this.isTrue(res))
      ));
  }

  async applyTax() {
    // if (!this.applyTax$) {
    //   this.applyTax$ = from(this.getSettingValue('Sales', 'ApplyTax')).pipe(
    //     map(res => res),
    //     shareReplay(1)
    //   );
    // }
    // return this.applyTax$.toPromise();
    return firstValueFrom(
      from(this.getSettingValue('Sales', 'ApplyTax'))
    );
  }

  async applyTaxAfterDiscount(): Promise<boolean> {
    const createVATJournalEntriesAllowed = await this.createVATJournalEntriesAllowed();
    if (createVATJournalEntriesAllowed) {
      const applyTax = await this.applyTax();
      return applyTax && applyTax === 'AfterDiscount';
    }
    return false;
  }

  async recalculateOrderDiscountToDeductFromNetTotal(): Promise<boolean> {
    return firstValueFrom(
      from(this.getSettingValue('Sales', 'RecalculateOrderDiscountToDeductFromNetTotal')).pipe(
        map(res => this.isTrue(res))
      ));
  }

  async deductDiscountFromNetTotal(): Promise<boolean> {
    const pricesIncludeVAT = await this.pricesIncludeVAT();
    if (!pricesIncludeVAT) return false;

    const createVATJournalEntriesAllowed = await this.createVATJournalEntriesAllowed();
    if (!createVATJournalEntriesAllowed) return false;

    const applyTax = await this.applyTax();
    if (!applyTax || applyTax !== 'AfterDiscount') return false;

    const deductFromNetTotal = await this.recalculateOrderDiscountToDeductFromNetTotal();
    return deductFromNetTotal;
  }

  async EReceipt_EGP_Connected(): Promise<boolean> {
    return firstValueFrom(
      from(this.getSettingValue('System', 'EReceipt_EGP_Connected')).pipe(
        map(res => this.isTrue(res))
      ));
  }

  async EReceipt_EGP_Paused(): Promise<boolean> {
    return firstValueFrom(
      from(this.getSettingValue('System', 'EReceipt_EGP_Paused')).pipe(
        map(res => this.isTrue(res))
      ));
  }

  async useUnitOfMeasure(): Promise<boolean> {
    // if (!this.useUnitOfMeasure$) {
    //   this.useUnitOfMeasure$ = from(this.getSettingValue('Warehouse', 'UseUnitOfMeasure')).pipe(
    //     map(res => this.isTrue(res)),
    //     shareReplay(1)
    //   );
    // }
    // return this.useUnitOfMeasure$.toPromise();
    return firstValueFrom(
      from(this.getSettingValue('Warehouse', 'UseUnitOfMeasure')).pipe(
        map(res => this.isTrue(res))
      ));
  }

  async allowUnpaidSalesOrders(): Promise<boolean> {
    // if (!this.allowUnpaidSalesOrders$) {
    //   this.allowUnpaidSalesOrders$ = from(this.getSettingValue('Sales', 'AllowUnpaidSalesOrders')).pipe(
    //     map(res => this.isTrue(res)),
    //     shareReplay(1)
    //   );
    // }
    // return this.allowUnpaidSalesOrders$.toPromise();
    return firstValueFrom(
      from(this.getSettingValue('Sales', 'AllowUnpaidSalesOrders')).pipe(
        map(res => this.isTrue(res))
      ));
  }

  async allowSalesOrderWithZeroTotal(): Promise<boolean> {
    // if (!this.allowSalesOrderWithZeroTotal$) {
    //   this.allowSalesOrderWithZeroTotal$ = from(this.getSettingValue('Sales', 'AllowSalesOrderWithZeroTotalValue')).pipe(
    //     map(res => this.isTrue(res)),
    //     shareReplay(1)
    //   );
    // }
    // return this.allowSalesOrderWithZeroTotal$.toPromise();
    return firstValueFrom(
      from(this.getSettingValue('Sales', 'AllowSalesOrderWithZeroTotalValue')).pipe(
        map(res => this.isTrue(res))
      ));
  }

  async allowZeroPriceInSalesOrderDetails(): Promise<boolean> {
    // if (!this.allowZeroPriceInSalesOrderDetails$) {
    //   this.allowZeroPriceInSalesOrderDetails$ = from(this.getSettingValue('Sales', 'AllowZeroPriceInSalesOrderDetails')).pipe(
    //     map(res => this.isTrue(res)),
    //     shareReplay(1)
    //   );
    // }
    // return this.allowZeroPriceInSalesOrderDetails$.toPromise();
    return firstValueFrom(
      from(this.getSettingValue('Sales', 'AllowZeroPriceInSalesOrderDetails')).pipe(
        map(res => this.isTrue(res))
      ));
  }

  async allowUseBatchNumber(): Promise<boolean> {
    return firstValueFrom(
      from(this.getSettingValue('Warehouse', 'UseBatchNumber')).pipe(
        map(res => this.isTrue(res))
      ));
  }

  async nameUnique(): Promise<boolean> {
    if (!this.nameUnique$) {
      this.nameUnique$ = from(this.getSettingValue('Sales', 'NameUnique')).pipe(
        map(res => this.isTrue(res)),
        shareReplay(1)
      );
    }
    return firstValueFrom(this.nameUnique$);
  }

  async codeUnique(): Promise<boolean> {
    if (!this.codeUnique$) {
      this.codeUnique$ = from(this.getSettingValue('Sales', 'CodeUnique')).pipe(
        map(res => this.isTrue(res)),
        shareReplay(1)
      );
    }
    return firstValueFrom(this.codeUnique$);
  }

  async codeRequired(): Promise<boolean> {
    if (!this.codeRequired$) {
      this.codeRequired$ = from(this.getSettingValue('Sales', 'CodeRequired')).pipe(
        map(res => this.isTrue(res)),
        shareReplay(1)
      );
    }
    return firstValueFrom(this.codeRequired$);
  }

  async emailUnique(): Promise<boolean> {
    if (!this.emailUnique$) {
      this.emailUnique$ = from(this.getSettingValue('Sales', 'EmailUnique')).pipe(
        map(res => this.isTrue(res)),
        shareReplay(1)
      );
    }
    return firstValueFrom(this.emailUnique$);
  }

  async emailRequired(): Promise<boolean> {
    if (!this.emailRequired$) {
      this.emailRequired$ = from(this.getSettingValue('Sales', 'EmailRequired')).pipe(
        map(res => this.isTrue(res)),
        shareReplay(1)
      );
    }
    return firstValueFrom(this.emailRequired$);
  }

  async mobileUnique(): Promise<boolean> {
    if (!this.mobileUnique$) {
      this.mobileUnique$ = from(this.getSettingValue('Sales', 'MobileUnique')).pipe(
        map(res => this.isTrue(res)),
        shareReplay(1)
      );
    }
    return firstValueFrom(this.mobileUnique$);
  }

  async mobileRequired(): Promise<boolean> {
    if (!this.mobileRequired$) {
      this.mobileRequired$ = from(this.getSettingValue('Sales', 'MobileRequired')).pipe(
        map(res => this.isTrue(res)),
        shareReplay(1)
      );
    }
    return firstValueFrom(this.mobileRequired$);
  }

  async phoneUnique(): Promise<boolean> {
    if (!this.phoneUnique$) {
      this.phoneUnique$ = from(this.getSettingValue('Sales', 'PhoneUnique')).pipe(
        map(res => this.isTrue(res)),
        shareReplay(1)
      );
    }
    return firstValueFrom(this.phoneUnique$);
  }

  async phoneRequired(): Promise<boolean> {
    if (!this.phoneRequired$) {
      this.phoneRequired$ = from(this.getSettingValue('Sales', 'PhoneRequired')).pipe(
        map(res => this.isTrue(res)),
        shareReplay(1)
      );
    }
    return firstValueFrom(this.phoneRequired$);
  }

  async defaultPaymentForUsers(): Promise<boolean> {
    // if (!this.defaultPaymentForUsers$) {
    //   this.defaultPaymentForUsers$ = from(this.getSettingValue('Sales', 'DefaultPaymentForUsers')).pipe(
    //     map(res => this.isTrue(res)),
    //     shareReplay(1)
    //   );
    // }
    // return this.defaultPaymentForUsers$.toPromise();
    return firstValueFrom(
      from(this.getSettingValue('Sales', 'DefaultPaymentForUsers')).pipe(
        map(res => this.isTrue(res))
      ));
  }

  async defaultPaymentCashUsersIds() {
    // if (!this.defaultPaymentCashUsersIds$) {
    //   this.defaultPaymentCashUsersIds$ = from(this.getSettingValue('Sales', 'DefaultPaymentCashUsersIds')).pipe(
    //     map(res => res),
    //     shareReplay(1)
    //   );
    // }
    // return this.defaultPaymentCashUsersIds$.toPromise();
    return firstValueFrom(
      from(this.getSettingValue('Sales', 'DefaultPaymentCashUsersIds'))
    );
  }

  async userDefaultPaymentIsCash(): Promise<boolean> {
    const defaultPaymentForUsers = await this.defaultPaymentForUsers();
    if (defaultPaymentForUsers === true) {
      const userIds = await this.defaultPaymentCashUsersIds();
      if (userIds) {
        const edaraUserId = this.authService.currentOrganization!.edara_user_id.toString();
        return this.commaSeparatedContainsValue(userIds, edaraUserId);
      }
    }
    return false;
  }

  async defaultValuationCost() {
    if (!this.defaultValuationCost$) {
      this.defaultValuationCost$ = from(this.getSettingValue('Warehouse', 'CurrentDefaultEvaluationCost')).pipe(
        shareReplay(1)
      );
    }
    return firstValueFrom(this.defaultValuationCost$);
  }

  async taxRegistrationId(): Promise<string> {
    if (!this.taxRegistrationId$) {
      this.taxRegistrationId$ = from(this.getSettingValue('System', 'TaxRegistrationID')).pipe(
        shareReplay(1)
      );
    }
    return firstValueFrom(this.taxRegistrationId$);
  }

  async organizationName(): Promise<string> {
    if (!this.organizationName$) {
      this.organizationName$ = from(this.getSettingValue('System', 'Organization')).pipe(
        shareReplay(1)
      );
    }
    return firstValueFrom(this.organizationName$);
  }

  async createSalesOrderNumber(): Promise<boolean> {
    return firstValueFrom(
      from(this.getSettingValue('Sales', 'CreateSalesOrderNumber')).pipe(
        map(res => this.isTrue(res))
      ));
  }

  async salesOrderNumberFormat(): Promise<string> {
    return firstValueFrom(
      from(this.getSettingValue('Sales', 'SONumberSeriesCombination'))
    );
  }

  async salesOrderNumberPrefix(): Promise<string> {
    return firstValueFrom(
      from(this.getSettingValue('Sales', 'SONumberSeriesCombinationPrefix'))
    );
  }

  async salesOrderNumberResetPeriod(): Promise<string> {
    return firstValueFrom(
      from(this.getSettingValue('Sales', 'SONumberResetSequence'))
    );
  }

  async soNumberSeriesLevel(): Promise<string> {
    return firstValueFrom(
      from(this.getSettingValue('Sales', 'SONumberSeriesLevel'))
    );
  }

  async userExistsOnAutoRelatedDocuments(): Promise<boolean> {
    const autoRelatedIO = await this.autoRelatedIO();
    if (autoRelatedIO) {
      const userIds = await this.autoRelatedDocumentsUsersIds();
      if (userIds) {
        const edaraUserId = this.authService.currentOrganization!.edara_user_id.toString();
        return this.commaSeparatedContainsValue(userIds, edaraUserId);
      }
    }
    return false;
  }

  async autoRelatedIO(): Promise<boolean> {
    return firstValueFrom(
      from(this.getSettingValue('Sales', 'AutoRelatedIO')).pipe(
        map(res => this.isTrue(res))
      ));
  }

  async autoRelatedDocumentsUsersIds() {
    return firstValueFrom(
      from(this.getSettingValue('Sales', 'AutoRelatedDocumentsUsersIds'))
    );
  }

  async createAPWithNewSupplier(): Promise<boolean> {
    return firstValueFrom(
      from(this.getSettingValue('Purchase', 'CreateAPWithNewSupplier')).pipe(
        map(res => this.isTrue(res))
      ));
  }

  async preventRepeatingStockitemInPO(): Promise<boolean> {
    return firstValueFrom(
      from(this.getSettingValue('Purchase', 'PreventRepeatingStockitemInPO')).pipe(
        map(res => this.isTrue(res))
      ));
  }

  async allowPOWithZeroPriceItems(): Promise<boolean> {
    return firstValueFrom(
      from(this.getSettingValue('Purchase', 'AllowPOWithZeroPriceItems')).pipe(
        map(res => this.isTrue(res))
      ));
  }

  private async getSetting(module: string, key: string): Promise<EdaraSetting | undefined> {
    const setting = await this.commonService.getSettingByKeyLocalAsync(module, key);
    if (setting && setting.id > 0) {
      return setting;
    } else {
      const res = await this.commonService.getSettingsByKeyAsync(module, key);
      return res ? res.result : undefined;
    }
  }

  private async getSettingValue(module: string, key: string, defaultValue: any = null): Promise<any> {
    try {
      const setting = await this.getSetting(module, key);
      if (setting && setting.value) {
        return setting.value;
      }
      return null;
    }
    catch (error) {
      return defaultValue;
    }
  }

  private isTrue(value: string): boolean {
    return !!value && (value.toUpperCase() === 'TRUE' || value.toUpperCase() === 'YES');
  }

  private commaSeparatedContainsValue(commaSeparatedStr: string, value: string): boolean {
    if (!commaSeparatedStr || !value) {
      return false;
    }
    return commaSeparatedStr.split(',').find(element => element === value) !== undefined;
  }

  private async preLoadSettings() {
    this.noOfDecimalPlacesMoney();
    this.noOfDecimalPlacesQty();
    this.allowDiscountPerStockItem();
    this.pricesIncludeVAT();

    this.nameUnique();
    this.codeUnique();
    this.codeRequired();
    this.emailUnique();
    this.emailRequired();
    this.mobileUnique();
    this.mobileRequired();
    this.phoneUnique();
    this.phoneRequired();
  }

  private clearCache() {
    this.noOfDecimalPlacesMoney$ = undefined;
    this.noOfDecimalPlacesQty$ = undefined;
    // this.allowDiscountPerStockItem$ = null;
    // this.pricesIncludeVAT$ = null;
    // this.SOIsTaxableByDefault$ = null;
    // this.allowSalesDiscounts$ = null;
    // this.createVATJournalEntriesAllowed$ = null;
    // this.applyTax$ = null;
    // this.useUnitOfMeasure$ = null;
    // this.defaultPaymentForUsers$ = null;
    // this.defaultPaymentCashUsersIds$ = null;
    // this.allowUnpaidSalesOrders$ = null;
    this.defaultValuationCost$ = undefined;
    // this.allowSalesOrderWithZeroTotal$ = null;
    // this.allowZeroPriceInSalesOrderDetails$ = null;
    // this.allowChangeSalesPrice$ = null;
    this.nameUnique$ = undefined;
    this.codeUnique$ = undefined;
    this.codeRequired$ = undefined;
    this.emailUnique$ = undefined;
    this.emailRequired$ = undefined;
    this.mobileUnique$ = undefined;
    this.mobileRequired$ = undefined;
    this.phoneUnique$ = undefined;
    this.phoneRequired$ = undefined;
    this.taxRegistrationId$ = undefined;
    this.organizationName$ = undefined;
  }

}
