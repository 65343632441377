import { NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeAr from '@angular/common/locales/ar';

import { ToolBarComponent } from './components/tool-bar/tool-bar.component';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';

import { SharedModule } from '@shared';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'src/material-module';
import { KeyboardShortcutsHelpComponent } from './components/keyboard-shortcuts-help/keyboard-shortcuts-help.component';
import { LockScreenComponent } from './components/lock-screen/lock-screen.component';
import { PreviewFeaturesComponent } from './components/preview-features/preview-features.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { QuickActionsComponent } from './components/quick-actions/quick-actions.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SideNavLinkComponent } from './components/side-nav-link/side-nav-link.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UpdateAppVersionDialogComponent } from './components/update-app-version-dialog/update-app-version-dialog.component';

registerLocaleData(localeAr, 'ar');

@NgModule({
  declarations: [
    ToolBarComponent,
    NavMenuComponent,
    LockScreenComponent,
    KeyboardShortcutsHelpComponent,
    PreviewFeaturesComponent,
    UserProfileComponent,
    QuickActionsComponent,
    SideNavLinkComponent,
    UpdateAppVersionDialogComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    KeyboardShortcutsModule.forRoot(),
    TranslateModule,
    MaterialModule,
    SharedModule,
    BrowserAnimationsModule
  ],
  exports: [
    ToolBarComponent,
    NavMenuComponent,
    LockScreenComponent,
    QuickActionsComponent
  ],
  providers: []
})
export class CoreModule { }
