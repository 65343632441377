import { Injectable } from '@angular/core';
import Dexie from 'dexie';

@Injectable({ providedIn: 'root' })
export class DexieDbProvider {

  private _db: any;
  public get db(): any {
    if (!this._db.isOpen()) {
      this.createDatabase();
    }

    return this._db;
  }

  constructor() {
    this.createDatabase();
  }

  public createDatabase() {

    // Check for support
    if (!('indexedDB' in window)) {
      console.log('This browser doesn\'t support IndexedDB');
      return;
    }

    this._db = new Dexie('EdaraPWA');
    this._db.version(1).stores({
      customers: 'id,code,name,phone,mobile,email,payment_type,credit_limit,balance',
      serviceItems: 'id,code,description,price',
      stockItems: 'id,code,description,sku,price,part_number',
      salesPersons: 'sales_person_id,sales_person_code,name,classification_code,credit_limit',
      currencies: 'id,symbol,description,international_code,currency_sub_unit',
      salesOrders: 'id,document_code,paper_number,document_date,customer_id,salesPerson_id,warehouse_id,currency_id',
      warehouses: 'id,code,description'
    });
    this._db.version(2).stores({
      parkedSalesOrders: '++id,document_code,paper_number,document_date,customer_id,salesPerson_id,warehouse_id,currency_id',
    });
    this._db.version(3).stores({
      accounts: 'account_id,account_code,description,account_type'
    });
    this._db.version(4).stores({
      customers: 'id,code,name,phone,mobile,email,payment_type',
      serviceItems: 'id,code,description',
      stockItems: 'id,code,description,sku,part_number',
      salesPersons: 'sales_person_id,sales_person_code,name,classification_code',
    }).upgrade((trans: any) => {
      // Remove credit_limit and balance from customer indexes
      trans.customers.toCollection().modify((customer: any) => {
        delete customer.credit_limit;
        delete customer.balance;
      });

      // Remove price from serviceItem indexes
      trans.serviceItems.toCollection().modify((serviceItem: any) => {
        delete serviceItem.price;
      });

      // Remove price from stockItem indexes
      trans.stockItems.toCollection().modify((stockItem: any) => {
        delete stockItem.price;
      });

      // Remove credit_limit from salesPerson indexes
      trans.salesPersons.toCollection().modify((salesPerson: any) => {
        delete salesPerson.credit_limit;
      });
    });
    this._db.version(5).stores({
      heldSalesOrders: '++id,document_code,paper_number,document_date,customer_id,salesPerson_id,warehouse_id,currency_id',
    }).upgrade((trans: any) => {
      // Move held sales orders (from parkedSalesOrders) to a new table named heldSalesOrders.
      trans.parkedSalesOrders.toCollection().each(async function (record: any) {
        trans.heldSalesOrders.put(record);
      });
    });
    this._db.version(6).stores({
      parkedSalesOrders: null,
    });
    this._db.version(7).stores({
      settings: 'id,module,key,[module+key]'
    });
    this._db.version(8).stores({
      printTemplates: 'id,module_name,template_name'
    });
    this._db.version(9).stores({
      salesStores: 'id,code,description',
      stockItems: null
    });
    this._db.version(10).stores({
      stockItems: 'id,code,description,sku,price,part_number,*uom_part_numbers'
    });
    this._db.version(11).stores({
      stockItems: 'id,code,description,sku,price,part_number,active,*uom_part_numbers'
    });
    this._db.version(12).stores({
      heldSalesOrders: '++id,document_code,paper_number,document_date,customer_id,salesPerson_id,warehouse_id,currency_id,organization_id',
      salesOrders: null
    });
    this._db.version(13).stores({
      // tslint:disable-next-line: max-line-length
      salesOrders: '++id,external_id,document_code,paper_number,document_date,customer_id,salesPerson_id,warehouse_id,[offlineMetadata.organizationId+offlineMetadata.syncPending]',
      syncMetadata: '++id,serverDateTime'
    });
    this._db.version(14).stores({
      salesBundles: 'id,description'
    });
    this._db.version(15).stores({
      systemSettings: 'id,name,value'
    });
    this._db.version(16).stores({
      salesOrders: '++id,external_id,document_code,paper_number,document_date,customer_id,salesPerson_id,warehouse_id,[offlineMetadata.organizationId+offlineMetadata.syncPending],[offlineMetadata.organizationId+offlineMetadata.syncPending+offlineMetadata.processingStartedAt]'
    });
    this._db.version(17).stores({
      salesOrders: null
    });
    this._db.version(18).stores({
      syncMetadata: null
    });
    this._db.version(19).stores({
      syncMetadata: '++id,organization_id,entity_name,status_code'
    });
    this._db.version(20).stores({
      FA_UnitOfMeasures: 'id,description',
      FA_AssetLocations: 'id,description',
      FA_AssetTypes: 'id,name',
    });
    this._db.version(21).stores({
      MFG_CostTypes: 'id,description'
    });
    this._db.version(22).stores({
      tags: 'id,name'
    });
    this._db.version(23).stores({
      stockItems: 'id,code,description,sku,price,part_number,active,*uom_part_numbers,*description_keywords'
    });
    this._db.version(24).stores({
      stockItemSerials: '++id,stock_item_id,warehouse_id,serial_number'
    });
    this._db.version(25).stores({
      SLS_Promotions: 'id,name,start_date,end_date'
    });
    this._db.version(26).stores({
      stockItemSerials: null
    });
    this._db.version(27).stores({
      stockItemSerials: '[stock_item_id+warehouse_id+serial_number],stock_item_id,warehouse_id,serial_number'
    });
    this._db.version(28).stores({
      SLS_CashRegisters: 'id,code,name,device_id,activated_user'
    });
    this._db.version(29).stores({
      customers: 'id,code,name,phone,mobile,email,payment_type,external_id'
    });
    this._db.version(30).stores({
      countries: 'id,name',
      states: 'id,name,countryId,countryCode'
    });
    this._db.version(31).stores({
      salesBundles: 'id,description,*description_keywords',
      serviceItems: 'id,code,description,*description_keywords'
    });
    this._db.version(32).stores({
      customers: 'id,code,name,phone,mobile,email,payment_type,external_id,*mobile_parts'
    });
    this._db.version(33).stores({
      syncMetadata: '++id,tenantId,serverDateTime,entityName,statusCode'
    }).upgrade((trans: any) => {
      trans.syncMetadata.toCollection().each((record: any) => {
        record.tenantId = record.organization_id;
        record.entityName = record.entity_name;
        record.serverDateTime = record.sync_date;
        record.statusCode = record.status_code;
        record.statusMessage = record.status_message;

        trans.syncMetadata.put(record);
      });
    });
    this._db.version(34).stores({
      SLS_Products: 'id,name,description,sku,partNumber,soldByWeight,type,*searchKeywords,createdDate',
      SLS_Customers_V3: 'id,name,mobile,phone,email,customerType,nationalIdOrPassportNo,taxRegistrationId,*mobileParts,createdDate',
      INV_Locations: 'id,name,mobile,createdDate',
    });
    this._db.version(35).stores({
      BlobImages: 'id,url'
    });
    this._db.version(36).stores({
      SLS_Locations: 'id,name,mobile,createdDate'
    });
    this._db.version(37).stores({
      Edara3Currencies: 'id,symbol,description,international_code,currency_sub_unit,is_system_currency'
    });
    this._db.version(38).stores({
      SLS_Promotions: null,
      SLS_CashRegisters: null,
      SLS_Products: null,
      SLS_Customers_V3: null,
      SLS_Locations: null,
      POS_Promotions: 'id,name,start_date,end_date',
      POS_CashRegisters: 'id,code,name,device_id,activated_user',
      POS_Products: 'id,name,description,sku,partNumber,soldByWeight,type,*searchKeywords,createdDate',
      POS_Customers_V3: 'id,name,mobile,phone,email,customerType,nationalIdOrPassportNo,taxRegistrationId,*mobileParts,createdDate',
      POS_Locations: 'id,name,mobile,createdDate'
    });
    this._db.version(39).stores({
      districts: 'id,name,stateId,stateCode,countryId,countryCode'
    });
    this._db.version(40).stores({
      POS_UnitOfMeasures: 'id,description,symbol',
    });
    this._db.version(41).stores({
      POS_UnitOfMeasures: 'id,name,symbol',
    });
    this._db.version(42).stores({
      POS_Products: 'id,name,description,sku,partNumber,soldByWeight,type,*uom_barcodes,*uom_SKUs,*searchKeywords,createdDate'
    });
    this._db.version(43).stores({
      heldSalesOrdersV3: '++holdId',
    });
    this._db.version(44).stores({
      heldSalesOrdersV3: '++holdId,code,preOrderCode,holdDate'
    });
    this._db.version(45).stores({
      POS_Products: 'id,name,description,sku,partNumber,soldByWeight,type,*uom_barcodes,*uom_SKUs,*searchKeywords,createdDate,*variant_barcodes,*variant_SKUs'
    });
    this._db.version(46).stores({
      INV_Locations: null
    });
    this._db.version(47).stores({
      POS_Customers_V3: 'id,localId,name,mobile,phone,email,customerType,nationalIdOrPassportNo,taxRegistrationId,*mobileParts,createdDate',
    });
    this._db.version(48).stores({
      POS_Taxes: 'id,name,rate',
    });
    this._db.version(49).stores({
      systemSettings: null
    });
    this._db.version(50).stores({
      systemSettings: 'id,name,value'
    });
    this._db.version(51).stores({
      POS_Promotions: null
    });
    this._db.version(52).stores({
      POS_Promotions: 'id,name,startDate,endDate',
    });
    this._db.version(53).stores({
      PRCH_PurchaseOrders: 'id,referenceNo,orderDate,receivedDate,receivedStatus,invoicedStatus,status,approvalStatus'
    });
    this.openDatabase();
  }

  private openDatabase() {
    this._db.open().catch((err: any) => {
      console.log('Error opening local database:');
      console.error(err.stack || err);
    });
  }

  /**
   * Delete local database
   */
  public deleteDatabase() {
    // Clear Setting data
    this._db.settings.clear();
    this._db.systemSettings.clear();
    this._db.syncMetadata.clear();

    // Clear Accounting data
    this._db.accounts.clear();
    this._db.currencies.clear();

    // Clear Warehouses data
    this._db.stockItems.clear();
    this._db.warehouses.clear();

    // Clear Purchasing data
    this._db.PRCH_PurchaseOrders.clear();

    // Clear Fixed Assets data
    this._db.FA_UnitOfMeasures.clear();
    this._db.FA_AssetLocations.clear();
    this._db.FA_AssetTypes.clear();

    // Clear Manufacturing data
    this._db.MFG_CostTypes.clear();

    // Clear POS data
    this._db.customers.clear();
    this._db.printTemplates.clear();
    this._db.salesBundles.clear();
    this._db.salesPersons.clear();
    this._db.salesStores.clear();
    this._db.serviceItems.clear();
    this._db.tags.clear();
    // this._db.heldSalesOrders.clear();
    this._db.POS_Promotions.clear();
    this._db.POS_CashRegisters.clear();
    this._db.POS_Products.clear();
    this._db.POS_Customers_V3.clear();
    this._db.POS_Locations.clear();
    this._db.POS_UnitOfMeasures.clear();

    // Clear BlobImages data
    this._db.BlobImages.clear();
  }

  /**
   * Handel errors
   */
  public handleErrors(err: any) {
    switch (err.name) {
      case 'InvalidStateError':
        this.createDatabase();
        break;
      default:
        console.error('Local database error: ' + err);
        break;
    }
  }
}
