import { InventoryManagementMethod } from "./Inventory-management-method";
import { SalesBundle } from "./sales-bundle";
import { SalesOrderSettings } from "./sales-order-settings";
import { ServiceItem } from "./service-item";
import { StockItem, StockItemSerial, UnitOfMeasure } from "./stock-item";

export class SalesOrderItem {
    serial?: number;
    stock_item_id?: number;
    stock_item_description?: string;
    service_item_id?: number;
    sales_bundle_id?: number;
    quantity: number;
    unit_of_measure_id?: number;
    unit_of_measure_name?: string;
    price!: number;
    tax_rate?: number;
    tax_id?: number;
    item_discount = 0;
    item_discount_type = 0;
    isSelected?: boolean;
    promotion?: string;
    related_serials: string[] = [];
    batch_number?: string;
    balance?: number;
    expiry_date?: Date;
    batchDetails?: BatchDetail[] = [];
    unitOfMeasures?: UnitOfMeasure[];
    stockItemSerials?: StockItemSerial[] = [];
    isBenefit?: boolean;
    isBuyXGetYPrerequisite?: boolean;
    originalItemId?: string | number;

    constructor(stockItem: StockItem | undefined, serviceItem: ServiceItem | undefined, salesBundle: SalesBundle | undefined,
        quantity: number, uom?: UnitOfMeasure, expiryDate?: Date, settings?: SalesOrderSettings, warehouseId?: number) {
        if (stockItem) {
            this.stock_item_id = stockItem.id;
            this.stock_item_description = stockItem.description;
            this.price = stockItem.price;
            this.tax_rate = stockItem.tax_rate;
            this.tax_id = stockItem.tax_id;

            this.addRelatedSerial(stockItem.stockItemSerial!);

            // Use Unit of Measure
            if (uom) {
                this.unitOfMeasures = stockItem.unit_of_measures;
                this.price = uom.uom_price;
                this.unit_of_measure_id = uom.unit_of_measure_id;
                this.unit_of_measure_name = uom.uom_name;
            }

            if (settings?.useBatchNumber && stockItem.warehouse_stocks?.length > 0) {
                // Set balance
                this.balance = stockItem.warehouse_stocks.filter(stock => stock.warehouse_id === warehouseId)
                    .map(x => x.balance).reduce((a, b, i) => a + b, 0);

                let warehouseStocks = stockItem.warehouse_stocks.filter(s => s.batch_number && s.warehouse_id === warehouseId && s.balance > 0);
                if (settings.inventoryManagementMethod === InventoryManagementMethod.FEFO) {
                    warehouseStocks.sort((a, b) => new Date(a.expiration_date) > new Date(b.expiration_date) ? 1 : -1);
                }

                warehouseStocks.forEach(s => {
                    this.batchDetails!.push({ batch_number: s.batch_number, expiry_date: s.expiration_date, balance: s.balance, quantity: 0 });
                });
            }
        } else if (serviceItem) {
            this.service_item_id = serviceItem.id;
            this.stock_item_description = serviceItem.description;
            this.price = serviceItem.price;
            this.tax_rate = serviceItem.tax_rate;
            this.tax_id = serviceItem.tax_id;
        } else if (salesBundle) {
            this.sales_bundle_id = salesBundle.id;
            this.stock_item_description = salesBundle.description;
            this.price = salesBundle.totalPrice;
            this.tax_rate = salesBundle.taxRate;
        }

        this.quantity = quantity;
        this.expiry_date = expiryDate;
    }

    get netPrice(): number {
        return this.price - this.discountValue;
    }

    get taxRate(): number {
        return (this.tax_rate ?? 0) / 100;
    }

    get grossTotal(): number {
        return this.price * this.quantity;
    }

    set grossTotal(value) {
        this.price = this.quantity > 0 ? value / this.quantity : 0;
    }

    get batchesQuantity(): number {
        if (this.batchDetails?.length === 0) return 0;
        return this.batchDetails!.map(x => x.quantity).reduce((a, b, i) => a + b, 0);
    }

    public static clone(salesOrderItem: any): SalesOrderItem {
        const newSalesOrderItem = new SalesOrderItem(undefined, undefined, undefined, 0);
        const clonedSalesOrderItem = Object.assign(newSalesOrderItem, salesOrderItem)
        clonedSalesOrderItem.batchDetails = [];
        if (salesOrderItem.batchDetails) {
            for (let index = 0; index < salesOrderItem.batchDetails.length; index++) {
                clonedSalesOrderItem.batchDetails.push(Object.assign({}, salesOrderItem.batchDetails[index]));
            }
        }

        return clonedSalesOrderItem;
    }

    public netAmount(pricesIncludeVAT: boolean): number {
        if (!pricesIncludeVAT) {
            return this.netPrice * this.quantity;
        } else {
            return this.vatExclusivePrice * this.quantity - this.totalDiscount(pricesIncludeVAT);
        }
    }

    public baseAmount(pricesIncludeVAT: boolean): number {
        if (!pricesIncludeVAT) {
            return this.price * this.quantity;
        } else {
            return this.vatExclusivePrice * this.quantity;
        }
    }

    public totalDiscount(pricesIncludeVAT: boolean): number {
        if (this.item_discount_type === 0) {
            return this.item_discount;
        } else {
            return this.discount(pricesIncludeVAT) * this.quantity;
        }
    }

    public addRelatedSerial(stockItemSerial: StockItemSerial) {
        if (stockItemSerial) {
            this.related_serials.push(stockItemSerial.serial_number);
            this.stockItemSerials!.push(stockItemSerial);
        }
    }

    public toListGroupedByBatch(): SalesOrderItem[] {
        if (this.batchDetails?.length === 0) return [SalesOrderItem.clone(this)];

        const groupedItems: SalesOrderItem[] = [];

        let qty = this.quantity;
        let discountValue = this.item_discount_type === 0 ? this.item_discount : 0;
        this.batchDetails!.filter(x => x.quantity > 0)
            .forEach(batch => {
                if (qty > 0) {
                    const newItem = SalesOrderItem.clone(this);
                    newItem.quantity = batch.quantity <= qty ? batch.quantity : qty;
                    newItem.batch_number = batch.batch_number;
                    newItem.expiry_date = batch.expiry_date;
                    const lineDiscountValue = this.quantity > 0 ? Math.floor((discountValue / this.quantity) * newItem.quantity) : 0;
                    // If discount type is value, distribute discount amount based on line item quantity to prevent discount duplication.
                    newItem.item_discount = this.item_discount_type === 0 ? lineDiscountValue : this.item_discount;
                    groupedItems.push(newItem);

                    qty -= newItem.quantity;
                    discountValue -= lineDiscountValue;
                }
            });

        if (qty > 0) {
            const newItem = SalesOrderItem.clone(this);
            newItem.quantity = qty;
            // If discount type is value, distribute discount amount based on line item quantity to prevent discount duplication.
            newItem.item_discount = this.item_discount_type === 0 ? discountValue : this.item_discount;
            groupedItems.push(newItem);
            discountValue = 0;
        }

        // Assign the rest of fractional amount of the discount to the last item
        if (discountValue > 0) {
            groupedItems[groupedItems.length - 1].item_discount += discountValue
        }

        return groupedItems.map(itm => itm.minify());
    }

    public refresh(settings: any) {
        if (settings?.useBatchNumber && settings?.inventoryManagementMethod === InventoryManagementMethod.FEFO) {
            this.distributeQunatityOnBatches();
        }
    }

    public updateBatchQuntity(settings: any) {
        if (this.expiry_date && settings?.useBatchNumber && settings?.inventoryManagementMethod !== InventoryManagementMethod.FEFO) {
            const selectedBatch = this.batchDetails?.find(batch => batch.expiry_date === this.expiry_date);
            if (selectedBatch)
                selectedBatch.quantity++
        }
    }

    public minify() {
        delete this.serial;
        delete this.isSelected;
        delete this.balance;
        delete this.batchDetails;
        delete this.unitOfMeasures;
        delete this.stockItemSerials;

        return this;
    }

    private distributeQunatityOnBatches() {
        if (this.batchDetails?.length) {
            this.resetBatchDetailsQuantity();
            let qty = this.quantity;
            while (qty > 0 && this.batchDetails.some(x => x.balance > x.quantity)) {
                const index = this.batchDetails.findIndex(x => x.balance > x.quantity);
                const availableBlance = this.batchDetails[index].balance;
                this.batchDetails[index].quantity = availableBlance > qty ? qty : availableBlance;
                qty -= availableBlance;
            }
        }
    }

    private resetBatchDetailsQuantity() {
        this.batchDetails!.forEach(element => element.quantity = 0);
    }

    private get vatExclusivePrice(): number {
        return this.price - this.vat;
    }

    private get vat(): number {
        return this.price - (this.price / (1 + this.taxRate));
    }

    private get discountValue(): number {
        if (this.item_discount_type === 0)
            return this.quantity > 0 ? this.item_discount / this.quantity : 0;
        else
            return this.price * this.item_discount / 100;
    }

    private discount(pricesIncludeVAT: boolean): number {
        if (this.item_discount_type === 0) {
            return this.item_discount;
        } else {
            const price = !pricesIncludeVAT ? this.price : this.vatExclusivePrice;
            return price * this.item_discount / 100;
        }
    }
}

export class BatchDetail {
    batch_number!: string;
    expiry_date?: Date;
    quantity!: number;
    balance!: number;
}

export class SerialDetail {
    isSelected?: boolean;
    isBalanceAvailable?: boolean;
    serial!: string;
    serialInfo?: StockItemSerial;
}
