<ng-container *ngFor="let condition of conditions; let i = index">
  <div class="d-flex align-items-center margin-bottom-base">
    <span class="margin-end-base filter-field-wrapper" *ngIf="i === 0">{{"gridFilter.where" |translate}}</span>
    <span class="margin-end-base filter-field-wrapper" *ngIf="i == 1 ">
      <div class="filter__dropdown margin-end-base">
        <mat-form-field appearance="outline">
          <mat-select  (selectionChange)="applyFilter()" [id]="'ddl_operator' + i"
            appearance="outline" name="type1" [(ngModel)]="operator">
            <mat-option [id]="'ddl_operator' + i +'_And'" value="AND">{{"gridFilter.and" |translate}}</mat-option>
            <mat-option [id]="'ddl_operator' + i +'_Or'" value="OR">{{"gridFilter.or" |translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </span>
    <span class="margin-end-base filter-field-wrapper" *ngIf="i > 1">
      <div class="filter__dropdown margin-end-base">
        <mat-form-field appearance="outline">
          <input matInput [ngModel]="'gridFilter.and' |translate" disabled name="operator" type="text" *ngIf="operator === 'AND'"/>
          <input matInput [ngModel]="'gridFilter.or' |translate" disabled name="operator" type="text" *ngIf="operator === 'OR'"/>
        </mat-form-field>
      </div>
    </span>
    <div class="filter__dropdown margin-end-base">
      <mat-form-field appearance="outline">
        <mat-select  appearance="outline" name="column1" [id]="'ddl_field' + i"
          (selectionChange)="applyFilter()"
          (selectionChange)="onSelectionColumnChange($event,condition,condition?.columnDefs )"
          [(ngModel)]="condition.column">
          <ng-container *ngFor="let item of (condition?.columnDefs || []) | agFilterColumns:conditions">
            <mat-option *ngIf="isColDef(item)" [value]="item.field" [id]="'ddl_field' + i + '_' + item.field"  [disabled]="getColumnUsageCount(item.field) >= 2"> {{item.headerName}} </mat-option>
          </ng-container>

        </mat-select>
      </mat-form-field>
    </div>
    <div class="filter__dropdown margin-end-base">
      <mat-form-field appearance="outline">

        <mat-select  appearance="outline" name="type1" [id]="'ddl_condition' + i"
          [(ngModel)]="condition.type" (selectionChange)="applyFilter()">
        <mat-option
          *ngFor="let item of (condition?.columnDefs || []) | agFilterColumnOperators: (condition.column || undefined)"
          [value]="item.code" [id]="'ddl_type' + i + '_' + item.code">{{item.value |translate}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <span [ngSwitch]="((condition?.columnDefs || []) | getAgFilterColumn:(condition.column || undefined))?.filter">
      <ng-container *ngSwitchCase="agGridFilterTypes.date">
        <span [ngSwitch]="condition.type">
          <ng-container *ngSwitchCase="agGridOperatorsEnum.inRange">
          <mat-form-field class="margin-end-s custom-filter-input" appearance="outline">
            <input (dateChange)="onDateChange($event, condition, 'filter')" [(ngModel)]="condition.filter" (input)="applyFilter()" matInput [matDatepicker]="fromTo" autocomplete="off" [id]="'textbox_gridFilterRangeFrom' + i">
            <mat-datepicker-toggle matSuffix [for]="fromTo"></mat-datepicker-toggle>
            <mat-datepicker #fromTo></mat-datepicker>
          </mat-form-field>
          <mat-form-field class="margin-end-s custom-filter-input" appearance="outline">
            <input (dateChange)="onDateChange($event, condition, 'filterTo')" [(ngModel)]="condition.filterTo" (input)="applyFilter()" matInput [matDatepicker]="dateTo" autocomplete="off" [id]="'textbox_gridFilterRangeTo' + i">
            <mat-datepicker-toggle matSuffix [for]="dateTo"></mat-datepicker-toggle>
            <mat-datepicker #dateTo></mat-datepicker>
          </mat-form-field>
          </ng-container>
          <ng-container *ngSwitchCase="agGridOperatorsEnum.blank">
            </ng-container>
            <ng-container *ngSwitchCase="agGridOperatorsEnum.notBlank">
            </ng-container>
          <ng-container *ngSwitchDefault>
            <mat-form-field class="margin-end-s custom-filter-input" appearance="outline">
              <input (dateChange)="onDateChange($event, condition, 'filter')" [(ngModel)]="condition.filter" (input)="applyFilter()" matInput [matDatepicker]="picker"
              autocomplete="off" [id]="'textbox_gridFilterValue' + i">
              <mat-datepicker-toggle  matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </ng-container>
        </span>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <span [ngSwitch]="condition.type">
            <ng-container *ngSwitchCase="agGridOperatorsEnum.inRange">
                <mat-form-field class="margin-end-s custom-filter-input"
                  [ngClass]="{'custom-filter-input--hide': operatorsWithoutInput.includes(condition.type || '')}" appearance="outline">
                  <input matInput (input)="applyFilter()" [disabled]="operatorsWithoutInput.includes(condition.type || '')"
                    [(ngModel)]="condition.filter" name="search" name="searchStr1" type="text" [id]="'textbox_gridFilterRangeFrom' + i"/>
                </mat-form-field>
                <mat-form-field class="margin-end-s custom-filter-input"
                  [ngClass]="{'custom-filter-input--hide': operatorsWithoutInput.includes(condition.type || '')}" appearance="outline">
                  <input matInput (input)="applyFilter()" [disabled]="operatorsWithoutInput.includes(condition.type || '')"
                    [(ngModel)]="condition.filterTo" name="search" name="searchStr1" type="text"  [id]="'textbox_gridFilterRangeTo' + i"/>
                </mat-form-field>
            </ng-container>
            <ng-container *ngSwitchCase="agGridOperatorsEnum.blank">
            </ng-container>
            <ng-container *ngSwitchCase="agGridOperatorsEnum.notBlank">
            </ng-container>
          <ng-container *ngSwitchDefault>
            <mat-form-field class="margin-end-s custom-filter-input"
              [ngClass]="{'custom-filter-input--hide': operatorsWithoutInput.includes(condition.type || '')}" appearance="outline">
              <input matInput (input)="applyFilter()" [disabled]="operatorsWithoutInput.includes(condition.type || '')"
                [(ngModel)]="condition.filter" name="search" name="searchStr1" type="text" [id]="'textbox_gridFilterValue' + i"/>
            </mat-form-field>
          </ng-container>
          </span>

      </ng-container>
    </span>
    <button mat-icon-button class="button--soft margin-inline-start-auto" (click)="removeCondition(condition)" [id]="'btn_gridRemoveFilter' + i">
      <icon name="delete"></icon>
    </button>
  </div>
</ng-container>
<button mat-button class="button--soft condition-button" (click)="addCondition()" id="btn_gridAddFilter">
  <icon name="add" class="margin-end-s"></icon>
  <span>{{'gridFilter.add_condition' | translate}}</span>
</button>
