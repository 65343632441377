import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-update-app-version-dialog',
  templateUrl: './update-app-version-dialog.component.html',
  styleUrl: './update-app-version-dialog.component.scss'
})
export class UpdateAppVersionDialogComponent {

  constructor(public dialogRef: MatDialogRef<UpdateAppVersionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {enforceUpdate: boolean }
  ) {
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
}
