<div class="dialog__height">
    <div mat-dialog-title>
        <div class="l-f-between dialog__header">
            <h2 class="typo-title">{{'keyboardShortcuts.title' | translate}}:</h2>
            <button id="btn_close" mat-icon-button mat-dialog-close>
                <icon name="close"></icon>
            </button>
        </div>
    </div>
    <mat-dialog-content>
        <div class="row">
            <div class="col-md-6">
                <h4>{{'keyboardShortcuts.navigation' | translate}}:</h4>
                <div class="cotainer">
                    <span class="key">F4</span>
                    <span class="seperators"> {{'keyboardShortcuts.or' | translate}} </span>
                    <span class="key">ctrl + l</span>
                    <span class="description"> {{'keyboardShortcuts.description.lock' | translate}}</span>
                </div>
                <div class="cotainer">
                    <span class="key">ctrl + shift + F4</span>
                    <span class="description"> {{'keyboardShortcuts.description.logout' | translate}}</span>
                </div>
                <div class="cotainer">
                    <span class="key">alt + shift + s</span>
                    <span class="description"> {{'keyboardShortcuts.description.settings' | translate}}</span>
                </div>
                <div class="cotainer">
                    <span class="key">alt + shift + t</span>
                    <span class="description"> {{'keyboardShortcuts.description.switchTenant' | translate}}</span>
                </div>
                <div class="cotainer">
                    <span class="key">alt + shift + x</span>
                    <span class="description"> {{'keyboardShortcuts.description.changeLanguage' | translate}}</span>
                </div>
                <div class="cotainer">
                    <span class="key">g</span>
                    <span class="seperators"> {{'keyboardShortcuts.then' | translate}} </span>
                    <span class="key">h</span>
                    <span class="description"> {{'keyboardShortcuts.description.goToHome' | translate}}</span>
                </div>
                <div class="cotainer">
                    <span class="key">n</span>
                    <span class="seperators"> {{'keyboardShortcuts.then' | translate}} </span>
                    <span class="key">c</span>
                    <span class="seperators"> {{'keyboardShortcuts.or' | translate}} </span>
                    <span class="key">g</span>
                    <span class="seperators"> {{'keyboardShortcuts.then' | translate}} </span>
                    <span class="key">c</span>
                    <span class="description"> {{'keyboardShortcuts.description.goToNewCustomer' | translate}}</span>
                </div>
                <div class="cotainer">
                    <span class="key">n</span>
                    <span class="seperators"> {{'keyboardShortcuts.then' | translate}} </span>
                    <span class="key">p</span>
                    <span class="seperators"> {{'keyboardShortcuts.or' | translate}} </span>
                    <span class="key">g</span>
                    <span class="seperators"> {{'keyboardShortcuts.then' | translate}} </span>
                    <span class="key">p</span>
                    <span class="description"> {{'keyboardShortcuts.description.goToNewProduct' | translate}}</span>
                </div>
                <div class="cotainer">
                    <span class="key">n</span>
                    <span class="seperators"> {{'keyboardShortcuts.then' | translate}} </span>
                    <span class="key">v</span>
                    <span class="seperators"> {{'keyboardShortcuts.or' | translate}} </span>
                    <span class="key">g</span>
                    <span class="seperators"> {{'keyboardShortcuts.then' | translate}} </span>
                    <span class="key">v</span>
                    <span class="description"> {{'keyboardShortcuts.description.goToNewServiceItem' | translate}}</span>
                </div>
                <div class="cotainer">
                    <span class="key">n</span>
                    <span class="seperators"> {{'keyboardShortcuts.then' | translate}} </span>
                    <span class="key">s</span>
                    <span class="seperators"> {{'keyboardShortcuts.or' | translate}} </span>
                    <span class="key">g</span>
                    <span class="seperators"> {{'keyboardShortcuts.then' | translate}} </span>
                    <span class="key">s</span>
                    <span class="description"> {{'keyboardShortcuts.description.goToNewSalesOrder' | translate}}</span>
                </div>
                <br>
                <h4>{{'keyboardShortcuts.actions' | translate}}:</h4>
                <div class="cotainer">
                    <span class="key">alt + a</span>
                    <span class="seperators"> {{'keyboardShortcuts.or' | translate}} </span>
                    <span class="key">ctrl + a</span>
                    <span class="description"> {{'keyboardShortcuts.description.selectAll' | translate}}</span>
                </div>
                <div class="cotainer">
                    <span class="key">alt + u</span>
                    <span class="seperators"> {{'keyboardShortcuts.or' | translate}} </span>
                    <span class="key">ctrl + u</span>
                    <span class="description"> {{'keyboardShortcuts.description.unselectAll' | translate}}</span>
                </div>
                <div class="cotainer">
                    <span class="key">del</span>
                    <span class="description"> {{'keyboardShortcuts.description.deleteSelectedRows' | translate}}</span>
                </div>
                <div class="cotainer">
                    <span class="key">home</span>
                    <span class="description"> {{'keyboardShortcuts.description.selectFirstRow' | translate}}</span>
                </div>
                <div class="cotainer">
                    <span class="key">end</span>
                    <span class="description"> {{'keyboardShortcuts.description.selectLastRow' | translate}}</span>
                </div>
                <div class="cotainer">
                    <span class="key">alt + right</span>
                    <span class="seperators"> {{'keyboardShortcuts.or' | translate}} </span>
                    <span class="key">=</span>
                    <span class="description"> {{'keyboardShortcuts.description.goToNextPage' | translate}}</span>
                </div>
                <div class="cotainer">
                    <span class="key">alt + left</span>
                    <span class="description"> {{'keyboardShortcuts.description.goToPrevioudPage' | translate}}</span>
                </div>
                <div class="cotainer">
                    <span class="key">/</span>
                    <span class="description"> {{'keyboardShortcuts.description.searchStockItem' | translate}}</span>
                </div>
                <div class="cotainer">
                    <span class="key">alt + c</span>
                    <span class="description"> {{'keyboardShortcuts.description.searchCustomer' | translate}}</span>
                </div>
            </div>
            <div class="col-md-6">
                <div class="cotainer">
                    <span class="key">F7</span>
                    <span class="seperators"> {{'keyboardShortcuts.or' | translate}} </span>
                    <span class="key">ctrl + s</span>
                    <span class="description"> {{'keyboardShortcuts.description.holdSalesOrder' | translate}}</span>
                </div>
                <div class="cotainer">
                    <span class="key">F8</span>
                    <span class="seperators"> {{'keyboardShortcuts.or' | translate}} </span>
                    <span class="key">ctrl + r</span>
                    <span class="description"> {{'keyboardShortcuts.description.retrieveSalesOrder' | translate}}</span>
                </div>
                <div class="cotainer">
                    <span class="key">shift + esc</span>
                    <span class="description"> {{'keyboardShortcuts.description.discardSalesOrder' | translate}}</span>
                </div>
                <div class="cotainer">
                    <span class="key">ctrl + enter</span>
                    <span class="seperators"> {{'keyboardShortcuts.or' | translate}} </span>
                    <span class="key">shift + enter</span>
                    <span class="description"> {{'keyboardShortcuts.description.pay' | translate}}</span>
                </div>
                <div class="cotainer">
                    <span class="key">f2</span>
                    <span class="description"> {{'keyboardShortcuts.description.paymentDone' | translate}}</span>
                </div>
                <div class="cotainer">
                    <span class="key">g</span>
                    <span class="seperators"> {{'keyboardShortcuts.then' | translate}} </span>
                    <span class="key">a</span>
                    <span class="description"> {{'keyboardShortcuts.description.goToCash' | translate}}</span>
                </div>
                <div class="cotainer">
                    <span class="key">g</span>
                    <span class="seperators"> {{'keyboardShortcuts.then' | translate}} </span>
                    <span class="key">t</span>
                    <span class="description"> {{'keyboardShortcuts.description.goToCreditCard' | translate}}</span>
                </div>
                <div class="cotainer">
                    <span class="key">g</span>
                    <span class="seperators"> {{'keyboardShortcuts.then' | translate}} </span>
                    <span class="key">o</span>
                    <span class="description"> {{'keyboardShortcuts.description.goToOnAccount' | translate}}</span>
                </div>
                <div class="cotainer">
                    <span class="key">alt + a</span>
                    <span class="description"> {{'keyboardShortcuts.description.goToCashAccount' | translate}}</span>
                </div>
                <div class="cotainer">
                    <span class="key">alt + b</span>
                    <span class="description"> {{'keyboardShortcuts.description.goToBankAccount' | translate}}</span>
                </div>
                <div class="cotainer">
                    <span class="key">alt + p</span>
                    <span class="description"> {{'keyboardShortcuts.description.goToPaidAmount' | translate}}</span>
                </div>
                <div class="cotainer">
                    <span class="key">esc</span>
                    <span class="seperators"> {{'keyboardShortcuts.then' | translate}} </span>
                    <span class="key">esc</span>
                    <span class="description"> {{'keyboardShortcuts.description.closePaymentControl' |
                        translate}}</span>
                </div>
                <div class="cotainer">
                    <span class="key">alt + q</span>
                    <span class="description"> {{'keyboardShortcuts.description.goToFirstQuantity' | translate}}</span>
                </div>
                <div class="cotainer">
                    <span class="key">alt + d</span>
                    <span class="description"> {{'keyboardShortcuts.description.goToFirstDiscountValue' |
                        translate}}</span>
                </div>
                <div class="cotainer">
                    <span class="key">alt + t</span>
                    <span class="description"> {{'keyboardShortcuts.description.goToFirstDiscountType' |
                        translate}}</span>
                </div>
                <div class="cotainer">
                    <span class="key">ctrl + q</span>
                    <span class="description"> {{'keyboardShortcuts.description.goToLastQuantity' | translate}}</span>
                </div>
                <div class="cotainer">
                    <span class="key">ctrl + d</span>
                    <span class="description"> {{'keyboardShortcuts.description.goToLastDiscountValue' |
                        translate}}</span>
                </div>
                <div class="cotainer">
                    <span class="key">ctrl + t</span>
                    <span class="description"> {{'keyboardShortcuts.description.goToLastDiscountType' |
                        translate}}</span>
                </div>
                <div class="cotainer">
                    <span class="key">f3</span>
                    <span class="description"> {{'keyboardShortcuts.description.goToOnCellUp' | translate}}</span>
                </div>
                <div class="cotainer">
                    <span class="key">shift + f3</span>
                    <span class="seperators"> {{'keyboardShortcuts.or' | translate}} </span>
                    <span class="key">=</span>
                    <span class="description"> {{'keyboardShortcuts.description.goToOnCellDown' | translate}}</span>
                </div>
            </div>
        </div>
    </mat-dialog-content>
</div>
