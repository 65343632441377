<ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>
<div class="top-bar">
  <div class="top-bar__start">
    <app-logo></app-logo>
  </div>
  <div class="top-bar__end">
    <a id="btn_settings" mat-icon-button [routerLink]="['/settings']" role="link">
      <icon name="settings"></icon>
    </a>
    <button id="btn_help" mat-icon-button [matMenuTriggerFor]="helpMenu" *ngIf="helpersService.isDesktop">
      <icon name="help"></icon>
    </button>
    <button mat-button id="btn_accountSettings" class="margin-start-base padding-y-xs" [matMenuTriggerFor]="menu"
      #clickMenuTrigger="matMenuTrigger" *ngIf="helpersService.isDesktop">
      <app-user-profile [userProfile]="userProfile" [currentOrganization]="currentOrganization"></app-user-profile>
    </button>
    <button mat-icon-button id="btn_accountSettings" class="padding-y-xs" [matMenuTriggerFor]="menu"
      #clickMenuTrigger="matMenuTrigger" *ngIf="!helpersService.isDesktop">
      <img class="user-profile__mob-avatar" [src]="userProfile?.picture">
    </button>
    <mat-menu (closed)="closedMenu()" #menu="matMenu" xPosition="before"
      [class]="'user-menu user-menu--organization-'+isSwitchOrganization">
      <div
        [ngClass]="isSwitchOrganization || isSwitchLanguage || isPreviewFeatures? 'user-menu__hide' : 'user-menu__show'">
        <div class="margin-bottom-base">
          <app-user-profile [userProfile]="userProfile" [currentOrganization]="currentOrganization"></app-user-profile>
        </div>
        <mat-divider></mat-divider>
        <a id="btn_switchLanguage" mat-menu-item (click)="$event.stopPropagation(); showSwitchLanguage()">
          <icon class="margin-end-s" name="public"></icon>
          <span class="margin-end-xxl">{{ 'tool-bar.menu.switch-language' | translate }}</span>
          <icon class="menu-arrow" name="arrow_right"></icon>
        </a>
        <a id="btn_userProfile" mat-menu-item (click)="$event.stopPropagation();">
          <icon class="margin-end-s" name="person"></icon>
          <span>{{ 'tool-bar.menu.userProfile' | translate }}</span>
        </a>
        <a id="btn_billingAccount" mat-menu-item (click)="$event.stopPropagation();">
          <icon class="margin-end-s" name="paid"></icon>
          <span>{{ 'tool-bar.menu.billingAccount' | translate }}</span>
        </a>
        <a id="btn_passCode" mat-menu-item [routerLink]="['/set-pass-code']">
          <icon class="margin-end-s" name="lock"></icon>
          <span>{{ 'tool-bar.menu.passCode' | translate }}</span>
        </a>
        <a id="btn_previewFeatures" mat-menu-item (click)="$event.stopPropagation();showPreviewFeatures()">
          <icon class="margin-end-s" name="star"></icon>
          <span class="margin-end-xxl">{{ 'tool-bar.menu.previewFeatures' | translate }}</span>
          <icon class="menu-arrow" name="arrow_right"></icon>
        </a>
        <mat-divider></mat-divider>
        <a id="btn_switchOrganization" mat-menu-item class="margin-y-s"
          (click)="$event.stopPropagation();showSwitchOrganization()"
          *ngIf="userOrganizations && userOrganizations.length > 1" [disabled]="!syncCompleted"
          [disableFeatureToggle]="{feature:'switchTenant', cssClass:'disableCssClass'}">
          <icon class="margin-end-s" name="work"></icon>
          <span class="">{{ 'tool-bar.menu.switch-tenant' | translate }}</span>
          <icon class="menu-arrow" name="arrow_right"></icon>
        </a>
        <mat-divider></mat-divider>
        <div class="margin-top-base">
          <button id="btn_signOut" mat-button color="primary" (click)="logout()" class="button--block">
            {{ 'tool-bar.menu.signOut' | translate }}
          </button>
        </div>
      </div>
      <div [ngClass]="isSwitchOrganization ? 'user-menu__show' : 'user-menu__hide'" class="user-menu__inner">
        <div class="user-menu__inner-header">
          <div class="d-flex">
            <button id="btn_back" class="flip-rtl" mat-icon-button
              (click)="$event.stopPropagation();showSwitchOrganization()">
              <icon name="chevron_left"></icon>
            </button>
            <h3 class="user-menu__inner-title">{{ 'tool-bar.menu.switchOrganization' | translate }}</h3>
          </div>
          <form action="">
            <mat-form-field class="user-menu__search-field" (click)="$event.stopPropagation();">
              <mat-label class="form-required" for="notesTb">
                {{ 'tool-bar.menu.findAnOrganization' | translate }}
              </mat-label>
              <icon name="search" matPrefix color="on-surface"></icon>
              <input matInput [placeholder]="('tool-bar.menu.findAnOrganization' | translate)"
                id="textbox_toolbarFindAnOrganization" (keyup)="filterUserMenu($event)">
            </mat-form-field>
          </form>
        </div>
        <div class="search-list">
          <button id="btn_currentOrganization"
            [ngClass]="{'user-menu__inner-button--selected': org.id === authService.currentOrganization?.id}"
            *ngFor="let org of filterdUserOrganizations; let i = index" mat-button class="button user-menu__inner-button"
            (click)="switchTenant(org.id)" [disabled]="!syncCompleted">
            <div class="d-flex align-items-center justify-content-between full-width">
             <div class="d-flex"> <span>{{ org.name }}</span> <icon class="margin-start-s" *ngIf="!i" name="domain"></icon></div>
              <icon name="done" class="user-menu__inner-checked-icon"></icon>
            </div>
          </button>
        </div>
      </div>
      <div [ngClass]="isPreviewFeatures ? 'user-menu__show' : 'user-menu__hide'" class="user-menu__inner">
        <div class="d-flex">
          <button id="btn_back" mat-icon-button
            (click)="$event.stopPropagation();isPreviewFeatures = !isPreviewFeatures">
            <icon name="chevron_left" class="user-menu__inner-icon"></icon>
          </button>
          <h3 class="user-menu__inner-title margin-bottom-none">{{ 'tool-bar.menu.previewFeatures' | translate }}</h3>
        </div>
        <app-preview-features (click)="$event.stopPropagation();"></app-preview-features>
      </div>
      <div [ngClass]="isSwitchLanguage ? 'user-menu__show' : 'user-menu__hide'" class="user-menu__inner">
        <div class="d-flex">
          <button id="btn_back" mat-icon-button (click)="$event.stopPropagation();isSwitchLanguage = !isSwitchLanguage">
            <icon name="chevron_left" class="user-menu__inner-icon"></icon>
          </button>
          <h3 class="user-menu__inner-title margin-bottom-none">
            {{ 'tool-bar.menu.switchLanguage' | translate }}
          </h3>
        </div>
        <div class="padding-bottom-m">
          <button id="btn_languageEn" mat-menu-item (click)="switchLanguageService.useLanguage('en')"
            [ngClass]="{'user-menu__inner-button--selected': switchLanguageService.currentLanguage === 'en'}">
            <span>English</span>
            <icon name="done" class="user-menu__inner-checked-icon"></icon>
          </button>
          <button id="btn_languageAr" mat-menu-item (click)="switchLanguageService.useLanguage('ar')"
            [ngClass]="{'user-menu__inner-button--selected': switchLanguageService.currentLanguage === 'ar'}">
            <span>العربية</span>
            <icon name="done" class="user-menu__inner-checked-icon"></icon>
          </button>
        </div>
      </div>
    </mat-menu>
    <mat-menu #helpMenu="matMenu" xPosition="before">
      <button id="btn_info" mat-menu-item (click)="help()">
        <icon name="info" class="margin-end-s"></icon>
        <span>{{ 'tool-bar.help.keyboardShortcuts' | translate }}</span>
      </button>
    </mat-menu>
  </div>
</div>