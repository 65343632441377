import { Component, Inject, Input, input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-hard-delete-confirmation-dialog',
  templateUrl: './hard-delete-confirmation-dialog.component.html',
  styleUrl: './hard-delete-confirmation-dialog.component.scss'
})
export class HardDeleteConfirmationDialogComponent {

  confirmText = '';

  constructor(
    private dialogRef: MatDialogRef<HardDeleteConfirmationDialogComponent>,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public dialogData: {
      entityName: string;
      message: string;
    }
  ) { }

  onConfirm(): void {
    if (this.confirmText.toLowerCase() === this.translateService.instant('hardDeleteConfirmationDialog.confirmText').toLowerCase()) {
      this.dialogRef.close(true);
    }
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }
}
