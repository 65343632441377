<div class="dialog__height">
    <div mat-dialog-title>
        <div class="dialog__header">
            <h2 class="typo-title" *ngIf="translateService.currentLang === 'en'">
                [{{dialogData.description}}] {{ "auditLog.history" | translate }}
            </h2>
            <h2 class="typo-title" *ngIf="translateService.currentLang === 'ar'">
                {{ "auditLog.history" | translate }} [{{dialogData.description}}]
            </h2>
            <button id="btn_close" mat-icon-button mat-dialog-close>
                <icon name="close"></icon>
            </button>
        </div>
    </div>
    <div id="spinner" class="spinner" *ngIf="loading"></div>
    <mat-dialog-content>
        <div *ngIf="auditEvents.length > 0">
            <div class="log-content" *ngFor="let auditEvent of auditEvents"
                [ngClass]="{ 'log-content-create': auditEvent.event_type === 'Create' }">
                <div class="l-f-between log-content-header">
                    <div class="d-flex align-items-center">
                        <icon name="account_circle"></icon>
                        <strong class="margin-inline-start-s">{{auditEvent.user.name}}</strong>
                    </div>
                    <span>{{auditEvent.event_date | date:'short':undefined:dateLocale}}</span>
                </div>
                <div class="log-content-create-title" *ngIf="auditEvent.event_type === 'Create'">
                    <span>{{ "auditLog.CreateDetail" | translate }} {{dialogData.description}}.</span>
                </div>
                <mat-accordion *ngIf="auditEvent.event_type !== 'Create'">
                    <mat-expansion-panel class="log-content__collapsable"
                        [disabled]="auditEvent.change_logs.length === 0">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{ "auditLog.madeChanges" | translate }}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        @switch (auditEvent.entity_name) {
                        @case ("WorkFlow") {
                        <ng-container *ngTemplateOutlet="workflowTemplate; context: { $implicit: auditEvent }">
                        </ng-container>
                        }
                        @default {
                        <ng-container *ngTemplateOutlet="genericTemplate; context: { $implicit: auditEvent }">
                        </ng-container>
                        }
                        }
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
        <div class="d-flex justify-content-center" *ngIf="!loading">
            <div class="log-footer" *ngIf="endOfResults && auditEvents.length > 0">
                - {{ "auditLog.endOfResult" | translate }} -
            </div>
            <div class="log-footer text-center" *ngIf="endOfResults && auditEvents.length === 0">
                <img src="/assets/images/emptystate.png" width="120" class="mb-4 mt-5">
                <p>- {{ "auditLog.noLogsFound" | translate }} -</p>
            </div>
            <button id="btn_showMore" mat-button color="primary"
                (click)="query.offset = query.offset + query.limit; findAuditLogs();" *ngIf="!endOfResults">
                {{ 'buttons.showMore' | translate }}
            </button>
        </div>
    </mat-dialog-content>
</div>

<ng-template #genericTemplate let-auditEvent>
    <div class="log-content-details" *ngFor="let changeLog of auditEvent.change_logs">
        <strong>{{changeTitle(changeLog)}}</strong>
        <div class="d-flex align-items-center" *ngFor="let valueChange of changeLog.value_changes">
            @switch (valueChange.property_name) {
            @case ('ExtraAttributes') {
            <div class="d-flex flex-column">
                <!-- Handle customFields changes -->
                <ng-container *ngIf="hasCustomFieldsChanges(valueChange)">
                    <ng-container *ngFor="let fieldId of getChangedCustomFieldIds(valueChange)">
                        <div class="d-flex align-items-center">
                            <strong>{{getCustomFieldName(fieldId)}}:</strong>
                            <strong class="original-value">
                                {{getCustomFieldValue(valueChange.original_value, fieldId)}}
                            </strong>
                            <icon class="flip-rtl" name="east"></icon>
                            <strong class="new-value">
                                {{getCustomFieldValue(valueChange.new_value, fieldId)}}
                            </strong>
                        </div>
                    </ng-container>
                </ng-container>

                <!-- Handle other ExtraAttributes changes (existing implementation) -->
                <ng-container *ngIf="!hasCustomFieldsChanges(valueChange)">
                    <ng-container *ngFor="let change of getExtraAttributesChanges(valueChange)">
                        <!-- Required Changes -->
                        <div *ngIf="isRequiredChange(change)" class="d-flex align-items-center">
                            <strong>{{change.label}}:</strong>
                            <strong class="original-value">
                                {{ 'auditLog.' + change.before | translate }}
                            </strong>
                            <icon class="flip-rtl" name="east"></icon>
                            <strong class="new-value">
                                {{ 'auditLog.' + change.after | translate }}
                            </strong>
                        </div>

                        <!-- Options Changes -->
                        <div *ngIf="isOptionsChange(change)" class="d-flex flex-column">
                            <span class="font-size-s">{{change.label}}:</span>
                            <strong class="typo-color--gray">{{ 'auditLog.before' | translate }}</strong>
                            <strong class="original-value">
                                <div *ngFor="let opt of change.before">• {{opt}}</div>
                            </strong>
                            <strong class="typo-color--primary">{{ 'auditLog.after' | translate }}</strong>
                            <strong class="new-value">
                                <div *ngFor="let opt of change.after">• {{opt}}</div>
                            </strong>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
            }
            @default {
            <strong>{{dialogData.translateKey + valueChange.property_name | translate}}:</strong>
            <strong class="original-value">
                {{ (valueChange.original_value | auditLogValueFormatter | async) }}
            </strong>
            <icon class="flip-rtl" name="east" *ngIf="valueChange.new_value"></icon>
            <strong class="new-value">
                {{valueChange.new_value | auditLogValueFormatter | async}}
            </strong>
            }
            }
        </div>
    </div>
</ng-template>

<ng-template #workflowTemplate let-auditEvent>
    <div class="log-content-details" *ngFor="let changeLog of auditEvent.change_logs">
        <div *ngFor="let valueChange of changeLog.value_changes; let last = last">
            @switch (valueChange.property_name) {
            @case ("Conditions") {
            <div class="d-flex flex-column">
                <span>{{dialogData.translateKey + valueChange.property_name | translate}}:</span>
                <strong class="typo-color--gray">
                    {{ 'auditLog.before' | translate }}
                </strong>
                <strong class="original-value ws-pre-line">
                    {{ (valueChange.original_value | workflowAuditLogValueFormatter).join('\n') }}
                </strong>
                <strong class="typo-color--primary">
                    {{ 'auditLog.after' | translate }}
                </strong>
                <strong class="new-value ws-pre-line">
                    {{ (valueChange.new_value | workflowAuditLogValueFormatter).join('\n') }}
                </strong>
            </div>
            }
            @case ("Approvers") {
            <div class="d-flex flex-column">
                <span>{{dialogData.translateKey + valueChange.property_name | translate}}:</span>
                <strong class="typo-color--gray">
                    {{ 'auditLog.before' | translate }}
                </strong>
                <div class="d-flex align-items-center bg-surface-2 border-radius-rounded fit-content p-1 mt-1"
                    *ngFor="let approver of (valueChange.original_value | workflowAuditLogValueFormatter)">
                    <small class="user-profile__avatar" [style.background-color]="approver.avatarBackgroundColor">
                        {{ approver.userInitals }}
                    </small>
                    <small class="margin-end-s">
                        {{ approver.email }}
                    </small>
                </div>
                <strong class="typo-color--primary">
                    {{ 'auditLog.after' | translate }}
                </strong>
                <div class="d-flex align-items-center bg-surface-2 border-radius-rounded fit-content p-1 mt-1"
                    *ngFor="let approver of (valueChange.new_value | workflowAuditLogValueFormatter)">
                    <small class="user-profile__avatar" [style.background-color]="approver.avatarBackgroundColor">
                        {{ approver.userInitals }}
                    </small>
                    <small class="margin-end-s">
                        {{ approver.email }}
                    </small>
                </div>
            </div>
            }
            @default {
            <div class="d-flex align-items-center">
                <strong>{{dialogData.translateKey + valueChange.property_name | translate}}:</strong>
                <strong class="original-value">
                    {{ (valueChange.original_value | auditLogValueFormatter | async) }}
                </strong>
                <icon class="flip-rtl" name="east" *ngIf="valueChange.new_value"></icon>
                <strong class="new-value">
                    {{valueChange.new_value | auditLogValueFormatter | async}}
                </strong>
            </div>
            }
            }
            <mat-divider class="my-3" *ngIf="!last"></mat-divider>
        </div>
    </div>
</ng-template>