<mat-form-field [class]="'full-width'+' '+ cssClass" [hideRequiredMarker]="hideRequiredMarker">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <mat-chip-grid id="{{inputId}}ChipGrid" #chipGrid [formControl]="formCtrl" [required]="required">
    @if (avatarChipTheme) {
    <mat-chip-row class="email-chip" *ngFor="let option of selectedOptions" [removable]="removable"
      (removed)="remove(option)">
      <div class="d-flex align-items-center">
        <small class="user-profile__avatar" [style.background-color]="option['avatarBackgroundColor']">
          {{ option['userInitals'] }}
        </small>
        <small class="margin-end-s">
          {{ option[textProperty] }}
        </small>
      </div>
      <button matChipRemove *ngIf="removable">
        <icon name="close"></icon>
      </button>
    </mat-chip-row>
    }
    @else {
    <mat-chip-row *ngFor="let option of selectedOptions" [removable]="removable" (removed)="remove(option)">
      <span class="chip-text">{{option[textProperty]}}</span>
      <button matChipRemove *ngIf="removable">
        <icon name="close" width="16" height="16"></icon>
      </button>
    </mat-chip-row>
    }
    <input placeholder="{{placeHolder}}" #chipInput [formControl]="autoCompleteControl" [matAutocomplete]="auto"
      [matChipInputFor]="chipGrid" (matChipInputTokenEnd)="add($event)" [id]="inputId" />
    <mat-spinner matSuffix diameter="25" *ngIf="selectedOptionsLoading"></mat-spinner>
    <icon name="info" color="on-surface" matSuffix matTooltip="{{ infoText }}" matTooltipPosition="above"
      *ngIf="infoText">
    </icon>
  </mat-chip-grid>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event.option.value)">
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
      {{option[textProperty]}}
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="required && requiredText && formCtrl.errors?.['required'] && formSubmitted">
    {{ requiredText }}
  </mat-error>
</mat-form-field>