import { Pipe, PipeTransform } from '@angular/core';
import { formatCurrency, formatDate } from '@angular/common';
import { SwitchLanguageService } from '../../core/services/switch-language.service';
import moment from 'moment';
import { CommonService } from '@core';
import { Currency } from '@shared/models/currency';
import { MoneyHelper } from '@shared/services/money-helper.service';

@Pipe({
  name: 'auditLogValueFormatter',
})
export class AuditLogValueFormatter implements PipeTransform {

  format = 'short';
  locale = 'en';
  currencies: Currency[] | null = null;

  constructor(private switchLanguageService: SwitchLanguageService, private commonService: CommonService, private moneyHelper: MoneyHelper) {
    this.locale = this.switchLanguageService.currentLanguage;

  }

  async isCurrency(value: string): Promise<boolean> {
    this.currencies ??= await this.commonService.getAllCurrenciesAsync();
    return this.currencies.some(c => value.includes(c.international_code));
  }

  async transform(value: string): Promise<string> {
    if (!value) {
      return 'NA';
    }

    // Check if the value is a valid date string
    if (moment(value, moment.ISO_8601, true).isValid() && isNaN(Number(value))) {
      return formatDate(
        new Date(value),
        this.format,
        this.locale
      );
    }

    if (await this.isCurrency(value)) {
      const currency = this.currencies!.find(c => value.includes(c.international_code))!;
      const amount = value.replace(currency.international_code, '').trim();
      const formattedAmount = formatCurrency(
        this.moneyHelper.round(Number(amount), 2),
        'en-US',
        '',
        '',
        '1.2-2'
      );
      return `${formattedAmount} ${currency.international_code}`;
    }

    // Return the value as is if it doesn't match any of the above conditions
    return value;
  }

}
