<div cdkDropList (cdkDropListDropped)="onReorderFromDrop($event)">
    <!-- Filter the columns to display only those with a headerName -->
    <ng-container *ngFor="let item of allColumnsState">
        <ng-container *ngIf="item.colRef?.headerName">
            <button mat-menu-item class="justify-content-between" [ngClass]="{'order-btn': !item.colRef?.headerName}"
                cdkDrag (drag)="dragleave($event)">
                <input type="checkbox" class="d-none" appFormControlChanges onFormControlChanges="onCheckboxChanged(1)"
                    name="{{item.colId}}" id="{{item.colId}}-checkbox" [ngModel]="item.hide"
                    (ngModelChange)="onCheckboxChanged($event)">
                <div class="d-flex align-items-center">
                    <icon name="drag_indicator" cdkDragHandle></icon>
                    <span>{{item.colRef?.headerName}}</span>
                </div>
                <icon name="visibility" (click)="changeVisibility(item, true)" *ngIf="!item.hide"></icon>
                <icon name="visibility_off" (click)="changeVisibility(item, false)" *ngIf="item.hide"></icon>
            </button>
        </ng-container>
    </ng-container>
</div>