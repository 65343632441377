<div class="dialog__height">
    <div mat-dialog-title>
      <div class="dialog__header no_border-bottom text-center d-block">
        <div class="icon-celebrate border-radius-50 bg-surface-3 margin-auto align-content-center">
            <img width="48" height="48" src="assets\images\icon-celebrate.png" alt="party-popper"/>
        </div>
        <h2 id="txt_confirmationDialogTitle" class="typo-title  mt-3 font-bold">
          {{ "updateMessageDialog.title" | translate }}
        </h2>
      </div>
    </div>
    <div class="bg-surface-3 py-5 border-radius-rounded mb-4 mx-3" mat-dialog-content >
      <p id="txt_confirmationDialogMessage" class="typo-body1" [innerHTML]="('updateMessageDialog.description' | translate)">
      </p>
    </div>
    <div mat-dialog-actions class="justify-content-end dialog__footer no_border-top d-grid mx-auto">
      <button mat-flat-button id="btn_confirmationDialogConfirm" class="px-5 py-1 mat-accent mat-flat-button mb-2" type="button" color="primary" (click)="onConfirm()">
        {{ "updateMessageDialog.UpdateNow" | translate }}
      </button>
      <span id="btn_confirmationDialogCancel" class="mb-1 pt-2 text-center opacity-6" type="button" (click)="onDismiss()"
      *ngIf="!data.enforceUpdate"> 
      {{ "updateMessageDialog.RemindMeLater" | translate }}
    </span>
    </div>
</div>