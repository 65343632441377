import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettingsService } from './app-settings.service';
import { Observable } from 'rxjs';
import { CustomField } from '@shared/models/custom-field';
import { PagedList, QueryStringHelper } from '@shared';

@Injectable({
  providedIn: 'root'
})
export class CustomFieldsService {

  baseUrl: string = '';

  constructor(private httpClient: HttpClient) {
    this.baseUrl = AppSettingsService.appSettings.edaraCoreApi.baseUrl;
    if (this.baseUrl.endsWith('/')) {
      this.baseUrl = this.baseUrl.slice(0, -1);
    }
  }

  getAllCustomFields(): Observable<CustomField[]> {
    return this.httpClient.get<CustomField[]>(`${this.baseUrl}/api/CustomFields`);
  }

  findCustomFields(query: any): Observable<PagedList<CustomField[]>> {
    const queryString = new QueryStringHelper().toQueryString(query);
    return this.httpClient.get<PagedList<CustomField[]>>(this.baseUrl + '/api/CustomFields/find?' + queryString);
  }

  getCustomFieldById(id: string): Observable<CustomField> {
    return this.httpClient.get<CustomField>(this.baseUrl + '/api/CustomFields/' + id);
  }

  createCustomField(customField: CustomField): Observable<CustomField> {
    return this.httpClient.post<CustomField>(this.baseUrl + '/api/CustomFields', customField);
  }

  updateCustomField(customField: CustomField): Observable<boolean> {
    return this.httpClient.put<boolean>(this.baseUrl + '/api/CustomFields/' + customField.id, customField);
  }

  deleteCustomField(customFieldId: string): Observable<boolean> {
    return this.httpClient.delete<boolean>(this.baseUrl + '/api/CustomFields/' + customFieldId);
  }

}
